import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createBIKReport, sendContactRequest, uploadCompanyBIKReport, uploadCustomerBIKReport } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import CommunicationPreferencesModal from 'src/components/CommunicationPreferencesModal';
import Footer from 'src/components/Footer';
import { LoadingScreen } from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';
import CompanyPart from 'src/pages/bik-upload/CompanyPart';
import CustomerPart from 'src/pages/bik-upload/CustomerPart';
import { bikUploadSchema } from 'src/pages/bik-upload/form.zod';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { BIKUploadValidationSchema } from 'src/types/FormField.type';

export default function ProcessBIK() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerRequestLoading, setIsCustomerRequestLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [customerType, setCustomerType] = useState<'OWNER' | 'SUPPORT'>('OWNER');
  const [failedReportId, setFailedReportId] = useState('');

  const handleClose = () => {
    setError(null);
    window.location.reload();
  };

  const handleClosePreferences = () => {
    setFailedReportId('');
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BIKUploadValidationSchema>({
    resolver: zodResolver(bikUploadSchema),
  });

  const onSubmit = async (data: BIKUploadValidationSchema) => {
    setIsLoading(true);
    let reportId;

    try {
      const createBIKReportResponse = await createBIKReport(
        data.type as 'OWNER' | 'SUPPORT',
        searchParams.get('ref') ?? undefined
      );

      reportId = createBIKReportResponse.reportId;
      if (data.attachments.companyPdf) {
        await uploadCompanyBIKReport(createBIKReportResponse.reportId, data.attachments.companyPdf);
      }

      if (data.attachments.customerPdf) {
        await uploadCustomerBIKReport(createBIKReportResponse.reportId, data.attachments.customerPdf);
      }

      navigate(`/process-bik/${createBIKReportResponse.reportId}/process`);
    } catch (error) {
      setError(handleApiError(error));
      reportId && setFailedReportId(reportId);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreferencesSubmit = async (data: CommunicationPreferences) => {
    setIsCustomerRequestLoading(true);

    try {
      await sendContactRequest(failedReportId, data);
    } catch (error) {
      setError(handleApiError(error));
      return false;
    } finally {
      setIsCustomerRequestLoading(false);
      setFailedReportId('');
    }

    setError('Dziękujemy za zgłoszenie.');
    return true;
  };

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />
      <LoadingScreen
        loadingMessage="Tworzenie wstępnego raportu. Proszę czekać..."
        open={isLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
      <Container
        id="process-bik"
        sx={{
          pt: { xs: 12, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 6, sm: 6 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box m={4}>
            <FormControl component="fieldset" sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">Jestem:</FormLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={customerType}
                render={({ field }) => (
                  <Box>
                    <RadioGroup
                      {...field}
                      onChange={(event) => {
                        setCustomerType(event.target.value as 'OWNER' | 'SUPPORT');
                        field.onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="OWNER"
                        control={<Radio />}
                        label="Przedsiębiorcą, chcę sprawdzić swój BIK"
                      />
                      <FormControlLabel
                        value="SUPPORT"
                        control={<Radio />}
                        label="Przedstawicielem Przedsiębiorcy, działam w jego imieniu aby sprawdzić BIK"
                      />
                    </RadioGroup>
                    {errors.type && <FormHelperText error>{errors.type.message}</FormHelperText>}
                  </Box>
                )}
              />
            </FormControl>
            {errors.attachments?.root && <FormHelperText error>{errors.attachments?.root.message}</FormHelperText>}

            <CompanyPart control={control} type={customerType} />
            <CustomerPart control={control} type={customerType} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  marginTop: 2,
                  fontSize: '16px',
                  borderRadius: '8px',
                }}
              >
                Sprawdź
              </Button>
            </Box>
            {errors.attachments?.root && <FormHelperText error>{errors.attachments?.root.message}</FormHelperText>}
          </Box>
        </form>
      </Container>
      <Footer />

      {/* Show the Communication Preferences Modal on error */}
      <CommunicationPreferencesModal
        open={!!failedReportId}
        isLoading={isCustomerRequestLoading}
        onClose={handleClosePreferences}
        onSubmit={handlePreferencesSubmit}
      />
    </>
  );
}
