import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { refreshToken } from 'src/api/bikApi';

interface JwtPayload {
  exp: number;
}

const TOKEN_KEY = 'authToken';

export const isUserLogged = (): boolean => {
  const token = localStorage.getItem(TOKEN_KEY);

  return isTokenValid(token);
};

export const logoutUser = (): void => localStorage.removeItem(TOKEN_KEY);

const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

export const checkJWTValidity = async (): Promise<void> => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) return;

  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;
    const timeUntilExpiration = decodedToken.exp - currentTime;

    if (timeUntilExpiration <= 1200) {
      const response = await refreshToken();

      if (response?.token) {
        localStorage.setItem(TOKEN_KEY, response.token);
      }
    }
  } catch (error) {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    return <Navigate to="/sign-in" replace />;
  }

  if (!isTokenValid(token)) {
    localStorage.removeItem(TOKEN_KEY);
    return <Navigate to="/sign-in?message=Zostałeś wylogowany, ponieważ upłynął czas twojej sesji." replace />;
  }

  return children;
};
