import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthPage from './sites/AuthPage.tsx';
import BIKReportPreview from './sites/BIKReportPreview.tsx';
import FAQ from './sites/FAQ.tsx';
import LandingPage from './sites/LandingPage.tsx';
import ProcessBIK from './sites/ProcessBIK.tsx';
import ShowBIKRaport from './sites/ShowBIKRaport.tsx';
import ThankYou from './sites/ThankYou.tsx';
import getTheme from './theme.tsx';

import { PrivateRoute } from './components/PrivateRoute.tsx';
import './index.css';
import AddDetails from './sites/AddDetails.tsx';
import NotFoundPage from './sites/NotFountPage.tsx';
import PartnerDashboard from './sites/PartnerDashboard.tsx';
import ResetPasswordPage from './sites/ResetPasswordPage.tsx';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    Component: LandingPage,
  },
  {
    path: '/faq',
    Component: FAQ,
  },
  {
    path: '/process-bik',
    Component: ProcessBIK,
  },
  {
    path: '/process-bik/:id/old-process',
    Component: ShowBIKRaport,
  },
  {
    path: '/process-bik/:id/process',
    Component: BIKReportPreview,
  },
  {
    path: '/process-bik/:id/add-details',
    Component: AddDetails,
  },
  {
    path: '/process-bik/:id/done',
    Component: ThankYou,
  },
  {
    path: '/sign-in',
    Component: AuthPage,
  },
  {
    path: '/set-new-password',
    Component: ResetPasswordPage,
  },
  {
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <PartnerDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    Component: NotFoundPage,
  },
]);

const theme = createTheme(getTheme('light'));

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  </StrictMode>
);
