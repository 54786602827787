import { FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BIKSection } from './styles';
import { BIKRequestRow } from './types';

interface BIKRequestContentProps {
  request: BIKRequestRow;
  index: number;
  isChecked: boolean;
  onCheckboxChange: (bikRequest: BIKRequestRow, index: number) => void;
}

export const BIKRequestContent: React.FC<BIKRequestContentProps> = ({
  request,
  index,
  isChecked,
  onCheckboxChange,
}) => {
  const isRemovable = request.enableToRemove;
  const alignCenter = { alignContent: 'center', textAlign: 'center' };

  return (
    <BIKSection
      container
      size={{ xs: 12 }}
      key={index}
      spacing={0}
      sx={{ borderColor: isChecked ? '#3daf3d63' : '#00000014' }}
      $isRemovable={isRemovable}
      onClick={() => isRemovable && onCheckboxChange(request, index)}
    >
      <Grid size={{ xs: 5, md: 7 }}>
        {request.commitment} <br /> {request.companyName}
      </Grid>

      <Grid size={{ xs: 3, md: 3 }} sx={alignCenter}>
        {isRemovable ? 'Tak' : 'Nie'}
      </Grid>

      {isRemovable && (
        <>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            <FormControlLabel
              control={
                <Switch
                  disabled={!isRemovable}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(request, index)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${index}` }}
                />
              }
              label=""
            />
          </Grid>

          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            {request.price} zł
          </Grid>
        </>
      )}
    </BIKSection>
  );
};
