import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';

export default function BIKNotFound({ reportData }: { reportData?: CompanyBIKSummary | string }) {
  if ((reportData as any as string) === 'Somewith went wrong with API.') {
    return (
      <>
        <ScrollToTop />
        <AppToolbar type="back" />
        <Container
          id="faq"
          sx={{
            pt: { xs: 12, sm: 12 },
            minHeight: '40vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: { xs: 4, sm: 4 },
          }}
        >
          <Typography variant="h4" color="primary" gutterBottom>
            Przepraszamy, wystąpił błąd podczas analizowania Twojego raportu BIK.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Nasz zespół pracuje nad rozwiązaniem problemu. Jeśli chcesz otrzymać raport, prosimy o kontakt na
            bik@bikfirmowy.pl, a skontaktujemy się z Tobą, gdy tylko wszystko będzie gotowe. Dziękujemy za cierpliwość!
          </Typography>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />
      <Container
        id="faq"
        sx={{
          pt: { xs: 12, sm: 12 },
          minHeight: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: { xs: 4, sm: 4 },
        }}
      >
        <Typography variant="h4" color="primary" gutterBottom>
          Nie możemy znaleźć raportu!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Wygląda na to, że raport o podanym adresie URL nie istnieje. Upewnij się, że podałeś prawidłowy link lub
          skontaktuj się z naszym działem wsparcia.
        </Typography>
      </Container>
      <Footer />
    </>
  );
}
