import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, FormLabel, Switch, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, FieldErrors, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCompanyForBIKReport,
  createCustomerForBIKReport,
  updateBIKReportUserData,
  useBIKReports,
  usePartnerDetails,
} from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Footer from 'src/components/Footer';
import { LoadingScreen } from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';
import { AddDetailsSchema, detailsSchema } from 'src/pages/add-details/form.zod';
import BIKNotFound from './commonSites/BIKNotFound';
import LoadingSite from './commonSites/LoadingSite';

// Utility type for nested keys
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: NonNullable<ObjectType[Key]> extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<NonNullable<ObjectType[Key]>>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

const initialData: AddDetailsSchema = {
  privateData: {
    fullName: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    postalCode: '',
    street: '',
    buildingNumber: '',
    pesel: '',
  },
  companyData: {
    companyName: '',
    invoiceEmail: '',
    country: '',
    city: '',
    postalCode: '',
    street: '',
    buildingNumber: '',
    nip: '',
    regon: '',
  },
  supportData: { fullName: '', phone: '', email: '' },
  marketingConsents: { phoneConsent: false, emailConsent: false },
};

const testData: AddDetailsSchema = {
  privateData: {
    fullName: 'Jan Kowalski',
    phone: '111111111',
    email: 'jan.kowalski@niematkiegomaila.pl',
    country: 'Polska',
    city: 'Krakow',
    postalCode: '33-333',
    street: 'Krakowska',
    buildingNumber: '1',
    pesel: '99999999999',
  },
  companyData: {
    companyName: 'Kowalski SA',
    invoiceEmail: 'jan.kowalski@niematkiegomaila.pl',
    country: 'Polska',
    city: 'Krakow',
    postalCode: '33-333',
    street: 'Krakowska',
    buildingNumber: '3',
    nip: '1231231212',
    regon: '123123123',
  },
  supportData: { fullName: 'Jan Kowalski', phone: '123123123', email: 'jan.kowalski@niematkiegomaila.pl' },
  marketingConsents: { phoneConsent: true, emailConsent: true },
};

let timer: NodeJS.Timeout;

const AddDetails: React.FC = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddDetailsSchema>({
    resolver: zodResolver(detailsSchema),
    defaultValues: initialData,
  });

  const { id } = useParams<{ id: string }>(); // UseParams typed
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [clickCount, setClickCount] = useState<number>(0);

  const { data, isLoading: isBIKReportLoading } = useBIKReports(id ?? 'Wrong_param');
  const { data: partnerDetails } = usePartnerDetails();

  useEffect(() => {
    if (!partnerDetails) {
      return;
    }

    setValue('supportData', {
      ...partnerDetails,
      fullName: `${partnerDetails.firstName} ${partnerDetails.lastName}`,
    });
  }, [partnerDetails]);

  // Handle click count and test data setting
  useEffect(() => {
    if (clickCount === 6) {
      data?.customer && setValue('privateData', testData.privateData);
      data?.company && setValue('companyData', testData.companyData);
      setValue('marketingConsents', testData.marketingConsents);
      setValue('supportData', testData.supportData);
      setClickCount(0);
    }

    if (clickCount > 0) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => setClickCount(0), 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [clickCount, data?.customer, setValue]);

  useEffect(() => {
    if (data?.id && !data?.customer) {
      setValue('privateData', undefined);
    }
    if (data?.id && !data?.company) {
      setValue('companyData', undefined);
    }
  }, [data?.customer, setValue]);

  // Submit handler
  const onSubmit: SubmitHandler<AddDetailsSchema> = async (data) => {
    setIsLoading(true);

    if (!id) {
      setError('Brak podanego poprawnego identyfikatora.');
      setIsLoading(false);
      return;
    }

    try {
      const companyResponse = data.companyData && (await createCompanyForBIKReport(data.companyData));

      const customerResponse = data.privateData && (await createCustomerForBIKReport(data.privateData));

      await updateBIKReportUserData(id, {
        companyId: companyResponse?.id,
        customerId: customerResponse?.id,
        contactPersonEmail: data.supportData.email,
        contactPersonFullName: data.supportData.fullName,
        contactPersonPhone: data.supportData.phone,
        contactPersonEmailConsent: data.marketingConsents.emailConsent,
        contactPersonPhoneConsent: data.marketingConsents.phoneConsent,
      });

      navigate(`/process-bik/${id}/done`);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsLoading(false);
    }
  };

  // Close error
  const handleClose = () => setError(null);

  const handleNumberField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, NestedKeyOf<AddDetailsSchema>>
  ) => {
    let value = event.target.value.replace(/\D/g, '');
    field.onChange(value);
  };

  if (isBIKReportLoading) {
    return <LoadingSite />;
  }

  if (!data?.id) {
    return <BIKNotFound />;
  }

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />
      <LoadingScreen
        loadingMessage="Dodawanie danych osobowych do raportu. Proszę czekać..."
        open={isLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
      <Container
        id="faq"
        sx={{ pt: { xs: 12, sm: 12 }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {data.company && (
            <>
              <SectionTitle
                title="Dane Firmy niezbędne do uzupełnienia dokumentów:"
                handleClick={() => setClickCount(clickCount + 1)}
              />
              <Grid container spacing={4} mb={2}>
                <FormField name="companyData.companyName" label="Nazwa firmy" control={control} errors={errors} />
                <FormField
                  name="companyData.invoiceEmail"
                  label="Adres e-mail, na który mają przychodzić faktury"
                  type="email"
                  control={control}
                  errors={errors}
                />
                <FormField name="companyData.country" label="Kraj" control={control} errors={errors} />
                <FormField name="companyData.city" label="Miasto" control={control} errors={errors} />
                <FormField
                  name="companyData.postalCode"
                  label="Kod pocztowy"
                  control={control}
                  errors={errors}
                  onChange={(event, field) => {
                    let value = event.target.value.replace(/\D/g, '');
                    if (value.length > 2) {
                      value = value.slice(0, 2) + '-' + value.slice(2);
                    }
                    field.onChange(value);
                  }}
                  maxLength={6}
                />
                <FormField name="companyData.street" label="Ulica" control={control} errors={errors} />
                <FormField
                  name="companyData.buildingNumber"
                  label="Numer budynku/biura"
                  control={control}
                  errors={errors}
                />
                <FormField
                  name="companyData.nip"
                  label="Numer NIP"
                  control={control}
                  errors={errors}
                  maxLength={10}
                  onChange={handleNumberField}
                />
                <FormField
                  name="companyData.regon"
                  label="REGON"
                  control={control}
                  errors={errors}
                  maxLength={14}
                  onChange={handleNumberField}
                />
              </Grid>
            </>
          )}

          {data.customer && (
            <>
              {/* Private Data Section */}
              <SectionTitle
                title="Dane Prywatne niezbędne do uzupełnienia dokumentów:"
                handleClick={() => setClickCount(clickCount + 1)}
              />
              <Grid container spacing={4}>
                <FormField name="privateData.fullName" label="Imię i Nazwisko" control={control} errors={errors} />
                <FormField
                  name="privateData.phone"
                  label="Telefon"
                  control={control}
                  errors={errors}
                  maxLength={9}
                  onChange={handleNumberField}
                />
                <FormField
                  name="privateData.email"
                  label="Adres Email"
                  type="email"
                  control={control}
                  errors={errors}
                />
                <FormField name="privateData.country" label="Kraj" control={control} errors={errors} />
                <FormField name="privateData.city" label="Miasto" control={control} errors={errors} />
                <FormField
                  name="privateData.postalCode"
                  label="Kod pocztowy"
                  control={control}
                  errors={errors}
                  maxLength={6}
                  onChange={(event, field) => {
                    let value = event.target.value.replace(/\D/g, '');
                    if (value.length > 2) {
                      value = value.slice(0, 2) + '-' + value.slice(2);
                    }
                    field.onChange(value);
                  }}
                />
                <FormField name="privateData.street" label="Ulica" control={control} errors={errors} />
                <FormField
                  name="privateData.buildingNumber"
                  label="Numer budynku/biura"
                  control={control}
                  errors={errors}
                />
                <FormField
                  name="privateData.pesel"
                  label="PESEL"
                  control={control}
                  errors={errors}
                  maxLength={11}
                  onChange={handleNumberField}
                />
              </Grid>
            </>
          )}

          {/* Support Data Section */}
          <SectionTitle title="Dane kontaktowe w sprawie obsługi dokumentacji do korekty BIK (np.: wyznaczony pracownik/ pośrednik):" />
          <Grid container spacing={4}>
            <FormField name="supportData.fullName" label="Imię i Nazwisko" control={control} errors={errors} />
            <FormField
              name="supportData.phone"
              label="Telefon"
              control={control}
              errors={errors}
              maxLength={9}
              onChange={handleNumberField}
            />
            <FormField name="supportData.email" label="Adres Email" type="email" control={control} errors={errors} />
          </Grid>

          {/* Marketing Consents Section */}
          <SectionTitle title="Zgody Marketingowe" />
          <Grid container spacing={4}>
            <MarketingConsentField
              name="marketingConsents.phoneConsent"
              label="Wyrażam zgodę na kontakt przez firma BIK Master, ul. Dobrego Pasterza 118D/51, 31-416 Kraków; NIP:
              7712703730, za pośrednictwem telefonu (połączenia telefoniczne, SMS, MMS), w celu przekazywania
              informacji handlowych, na podany przeze mnie numer telefonu."
              control={control}
              errors={errors}
            />
            <MarketingConsentField
              name="marketingConsents.emailConsent"
              label="  Wyrażam zgodę na przesyłanie przez firma BIK Master, ul. Dobrego Pasterza 118D/51, 31-416 Kraków;
              NIP: 7712703730; informacji handlowych, za pomocą środków komunikacji elektronicznej, na podany
              przeze mnie adres e-mail."
              control={control}
              errors={errors}
            />
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              sx={{ fontSize: '16px', borderRadius: '8px' }}
            >
              Wyślij
            </Button>
          </Box>
        </form>
      </Container>
      <Footer />
    </>
  );
};

interface SectionTitleProps {
  title: string;
  handleClick?: () => void;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, handleClick }) => (
  <Typography onClick={handleClick} variant="h5" gutterBottom mt={4}>
    {title}
  </Typography>
);

function getNestedError<T>(errors: T, name: string): any {
  return name.split('.').reduce((acc, key) => {
    if (acc && typeof acc === 'object') {
      return (acc as Record<string, any>)[key]; // Casting acc to a Record to ensure type safety
    }
    return undefined;
  }, errors);
}
interface FormFieldProps {
  name: NestedKeyOf<AddDetailsSchema>; // Updated name type
  label: string;
  maxLength?: number;
  control: any; // react-hook-form control type
  errors: FieldErrors<AddDetailsSchema>;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, NestedKeyOf<AddDetailsSchema>>
  ) => void;
  type?: string;
}

const FormField: React.FC<FormFieldProps> = ({ name, label, control, type = 'text', errors, maxLength, onChange }) => {
  const errorMessage = getNestedError(errors, name)?.message; // Use getNestedError to access full path

  return (
    <Grid size={{ xs: 12, sm: 6 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            type={type}
            fullWidth
            value={field.value || ''}
            onChange={(event) => (onChange ? onChange(event, field) : field.onChange(event.target.value))}
            error={!!errorMessage} // Sets red border if error exists
            helperText={errorMessage || ' '} // Reserve space for the error message
            slotProps={{
              formHelperText: {
                style: {
                  margin: 0,
                  height: 0,
                },
              },
              htmlInput: {
                maxLength: maxLength, // Set maxLength restriction for input
                inputMode: type === 'number' ? 'numeric' : undefined, // Set input mode to numeric for number fields
                pattern: type === 'number' ? '[0-9]*' : undefined, // Allow only digits (0-9)
              },
            }}
          />
        )}
      />
    </Grid>
  );
};
interface MarketingConsentFieldProps {
  name: NestedKeyOf<AddDetailsSchema>; // Correct type for nested keys
  label: string;
  control: any;
  errors: FieldErrors<AddDetailsSchema>;
}

const MarketingConsentField: React.FC<MarketingConsentFieldProps> = ({ name, label, control, errors }) => {
  const errorMessage = getNestedError(errors, name)?.message;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid size={{ xs: 11 }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 1 }} container justifyContent="flex-end">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              onChange={(event) => field.onChange(event.target.checked)}
              color="primary"
            />
          )}
        />
      </Grid>
      <Typography
        variant="caption"
        color="error"
        mt={1}
        style={{ height: '0.5em' }} // Reserve space for the error message
      >
        {errorMessage}
      </Typography>
    </Grid>
  );
};

export default AddDetails;
