import { DialogContentText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKCompany, BIKPrivate } from 'src/types/CompanyBIKSummary.type';
import { SelectedRow } from 'src/types/SelectedRow';
import { BIKItemContent } from './BIKItemContent';
import { BIKRequestContent } from './BIKRequestContent';
import { BIKItem, GridTitle } from './styles';
import { BIKItemRow, BIKRequestRow } from './types';

const PRICES = {
  openItemPrice: 299,
  closedItemPrice: 199,
  bikRequest: 99,
};

function BIKReportContent({
  data,
  type,
  isSelectedAll,
  onSelectedRows,
}: {
  data: BIKCompany | BIKPrivate;
  type: BIKReportType;
  isSelectedAll: boolean;
  onSelectedRows: (itemsIndex: SelectedRow[], requestsIndex: SelectedRow[], type: BIKReportType) => void;
}) {
  const [checkedRequests, setCheckedRequests] = useState<SelectedRow[]>([]);
  const [checkedItems, setCheckedItems] = useState<SelectedRow[]>([]);
  const [isSelectAllItemsChecked, setIsSelectAllItemsChecked] = useState(isSelectedAll);
  const [isSelectAllRequestsChecked, setIsSelectAllRequestsChecked] = useState(isSelectedAll);

  const handleCheckboxItemsChange = (bikItem: BIKItemRow, index: number) => {
    if (!!checkedItems.find((item) => item.id === index)) {
      setCheckedItems(checkedItems.filter((i) => i.id !== index));
    } else {
      setCheckedItems([
        ...checkedItems,
        {
          id: index,
          bikReportType: type,
          price: bikItem.price,
          longestDebt: bikItem.longestDebt,
          longestDebtAt: bikItem.longestDebtAt,
          creditorId: bikItem.creditorId,
          signedOn: bikItem.signedOn,
        },
      ]);
    }
    setIsSelectAllItemsChecked(false);
  };

  const handleCheckboxRequestsChange = (bikRequest: BIKRequestRow, index: number) => {
    if (!!checkedRequests.find((item) => item.id === index)) {
      setCheckedRequests(checkedRequests.filter((i) => i.id !== index));
    } else {
      setCheckedRequests([
        ...checkedRequests,
        {
          id: index,
          bikReportType: type,
          price: bikRequest.price,
          creditorId: bikRequest.creditorId,
          signedOn: bikRequest.date,
        },
      ]);
    }

    setIsSelectAllRequestsChecked(false);
  };

  const openItems: BIKItemRow[] = useMemo(
    () =>
      data.items
        .filter((item) => item.isOpen)
        .map((item, key) => ({ ...item, price: PRICES.openItemPrice, sequenceNumber: key })),
    [data.items]
  );
  const closedItems: BIKItemRow[] = useMemo(() => {
    const openItemsLength = openItems.length;

    return data.items
      .filter((item) => !item.isOpen)
      .map((item, key) => {
        return {
          ...item,
          price: PRICES.closedItemPrice,
          sequenceNumber: key + openItemsLength,
        };
      });
  }, [data.items, openItems]);

  const requests: BIKRequestRow[] = useMemo(() => {
    return data.requests.map((item, key) => {
      return {
        ...item,
        price: PRICES.bikRequest,
        sequenceNumber: key,
      };
    });
  }, [data.requests]);

  const onChangeAllItems = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableOpenItems: SelectedRow[] = openItems
        .filter((item) => (isSelectedAll ? item.enableToRemove && item.longestDebt !== 0 : item.enableToRemove))
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          longestDebt: item.longestDebt,
          longestDebtAt: item.longestDebtAt,
          creditorId: item.creditorId,
          signedOn: item.signedOn,
        }));

      const allSelectableClosedItems: SelectedRow[] = closedItems
        .filter((item) => (isSelectedAll ? item.enableToRemove && item.longestDebt !== 0 : item.enableToRemove))
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          longestDebt: item.longestDebt,
          longestDebtAt: item.longestDebtAt,
          creditorId: item.creditorId,
          signedOn: item.signedOn,
        }));

      if (isSelectedAll !== undefined) {
        setIsSelectAllItemsChecked(isSelectedAll);
        setCheckedItems(isSelectedAll ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
        return;
      }

      setIsSelectAllItemsChecked(!isSelectAllItemsChecked);
      setCheckedItems(!isSelectAllItemsChecked ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
    },
    [openItems, closedItems, checkedItems, isSelectAllItemsChecked, type]
  );

  const onChangeAllRequests = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableRequests: SelectedRow[] = requests
        .filter((item) => item.enableToRemove)
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          creditorId: item.creditorId,
          signedOn: item.date,
        }));

      if (isSelectedAll !== undefined) {
        setIsSelectAllRequestsChecked(isSelectedAll);
        setCheckedRequests(isSelectedAll ? allSelectableRequests : []);
        return;
      }

      setIsSelectAllRequestsChecked(!isSelectAllRequestsChecked);
      setCheckedRequests(!isSelectAllRequestsChecked ? allSelectableRequests : []);
    },
    [requests, checkedRequests, isSelectAllRequestsChecked, type]
  );

  useEffect(() => {
    if (type === 'COMPANY') {
      onChangeAllItems(isSelectedAll);
      onChangeAllRequests(isSelectedAll);
    } else {
      setTimeout(() => {
        onChangeAllItems(isSelectedAll);
        onChangeAllRequests(isSelectedAll);
      }, 100);
    }
  }, [isSelectedAll, type]);

  useEffect(() => {
    onSelectedRows(checkedItems, checkedRequests, type);
  }, [checkedRequests, checkedItems, isSelectedAll]);

  return (
    <DialogContentText component="div">
      <Grid container spacing={1.5}>
        {/* Informacje ogólne */}
        <Grid size={{ xs: 6 }}>
          <BIKItem>
            <Typography variant="body1">
              <b>Nazwa firmy:</b> {data.name}
            </Typography>
          </BIKItem>
        </Grid>

        <Grid size={{ xs: 6 }}>
          {'nip' in data && (
            <BIKItem>
              <Typography variant="body1">
                <b>NIP:</b> {data.nip}
              </Typography>
            </BIKItem>
          )}
          {'pesel' in data && (
            <BIKItem>
              <Typography variant="body1">
                <b>PESEL:</b> {data.pesel || 'brak danych'}
              </Typography>
            </BIKItem>
          )}
        </Grid>

        {!!data?.items?.length && (
          <>
            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="h5">Zobowiązania kredytowe</Typography>
              </BIKItem>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="body1">
                  Ilość możliwa do usunięcia: {data.items.filter((item) => item?.enableToRemove).length}
                  {!!data.items.filter((item) => item?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllItems()}
                    >
                      <b>{isSelectAllItemsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </BIKItem>
            </Grid>

            {!!openItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">Zobowiązania kredytowe w trakcie spłaty:</Typography>
                </Grid>
                <>
                  <GridTitle size={{ xs: 3, md: 6 }}>Typ</GridTitle>
                  <GridTitle
                    size={{ xs: 2, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Historia spłacania
                  </GridTitle>
                  <GridTitle
                    size={{ xs: 3, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Rekomendowane do usunięcia
                  </GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Cena</GridTitle>
                </>

                {openItems.map((item) => (
                  <BIKItemContent
                    item={item}
                    index={item.sequenceNumber}
                    key={item.sequenceNumber}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.sequenceNumber)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}

            {!!closedItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">
                    Zobowiązania kredytowe - zamknięte (w ciągu ostatnich 60 miesięcy):
                  </Typography>
                </Grid>
                <>
                  <GridTitle size={{ xs: 3, md: 6 }}>Typ</GridTitle>
                  <GridTitle
                    size={{ xs: 2, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Historia spłacania
                  </GridTitle>
                  <GridTitle
                    size={{ xs: 3, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Rekomendowane do usunięcia
                  </GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Cena</GridTitle>
                </>
                {closedItems.map((item) => (
                  <BIKItemContent
                    item={item}
                    index={item.sequenceNumber}
                    key={item.sequenceNumber}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.sequenceNumber)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}
          </>
        )}

        {/* Sekcja zapytań do banków */}
        {!!requests.length && (
          <>
            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="h5">Zapytania kredytowe do BIK:</Typography>
              </BIKItem>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="body1">
                  Ilość możliwa do usunięcia: {requests.filter((request) => request?.enableToRemove).length}
                  {!!requests.filter((request) => request?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllRequests()}
                    >
                      <b>{isSelectAllRequestsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </BIKItem>
            </Grid>
            <>
              <GridTitle size={{ xs: 5, md: 7 }}>Typ</GridTitle>
              <GridTitle size={{ xs: 3, md: 3 }}>Rekomendowane do usunięcia</GridTitle>
              <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
              <GridTitle size={{ xs: 2, md: 1 }}>Cena</GridTitle>
            </>
            {requests.map((request) => (
              <BIKRequestContent
                index={request.sequenceNumber}
                request={request}
                key={request.sequenceNumber}
                isChecked={!!checkedRequests.find((item) => item.id === request.sequenceNumber)}
                onCheckboxChange={handleCheckboxRequestsChange}
              />
            ))}
          </>
        )}
      </Grid>
    </DialogContentText>
  );
}

export default memo(BIKReportContent);
