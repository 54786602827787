import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormLabel,
  Modal,
  Card as MuiCard,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword, signInPartner } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { Logo } from 'src/components/app-toolbar/AppToolbar.styles';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow: 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

// Type for form errors
interface FormErrors {
  email: string;
  password: string;
}

export default function SignInCard() {
  const [formErrors, setFormErrors] = useState<FormErrors>({ email: '', password: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingReset, setIsLoadingReset] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [resetEmail, setResetEmail] = useState<string>('');
  const [resetInfo, setResetInfo] = useState<string>('');
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const validateInputs = useCallback((): boolean => {
    const email = emailRef.current?.value || '';
    const password = passwordRef.current?.value || '';
    const errors: FormErrors = { email: '', password: '' };
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Proszę podać prawidłowy adres email.';
      isValid = false;
    }
    if (!password || password.length < 6) {
      errors.password = 'Hasło musi mieć co najmniej 6 znaków.';
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!validateInputs()) return;

    setLoading(true);
    try {
      const email = emailRef.current?.value || '';
      const password = passwordRef.current?.value || '';

      const response = await signInPartner(email, password);

      const { token } = response;

      if (token) {
        localStorage.setItem('authToken', token);
        navigate('/dashboard');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      setFormErrors({ email: handleApiError(error), password: '' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    setResetInfo(''); // Reset any previous messages
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setResetEmail('');
    setResetInfo('');
  };

  const handleResetPassword = async () => {
    if (!/\S+@\S+\.\S+/.test(resetEmail)) {
      setResetInfo('Proszę podać prawidłowy adres email.');
      return;
    }

    setIsLoadingReset(true);

    try {
      const response = await resetPassword(resetEmail);
      if (response.status === 204) {
        setResetInfo('Instrukcje dotyczące resetowania hasła zostały wysłane na podany adres email.');
      } else {
        setResetInfo('Nie udało się zresetować hasła. Spróbuj ponownie później.');
      }
      setIsLoadingReset(false);
    } catch (error) {
      setResetInfo(handleApiError(error));
      setIsLoadingReset(false);
    }
  };

  return (
    <>
      <Card variant="outlined">
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Logo src="/logo.png" alt="BIK Logo" />
        </Box>
        <Typography component="h1" variant="h4" sx={{ fontSize: { xs: '24px', sm: '28px' }, mb: 2 }}>
          Logowanie do panelu partnerskiego
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              inputRef={emailRef}
              error={!!formErrors.email}
              helperText={formErrors.email}
              id="email"
              type="email"
              name="email"
              placeholder="your@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Hasło</FormLabel>
            <TextField
              inputRef={passwordRef}
              error={!!formErrors.password}
              helperText={formErrors.password}
              name="password"
              type="password"
              id="password"
              placeholder="••••••"
              autoComplete="current-password"
              required
              fullWidth
              variant="outlined"
            />
          </FormControl>
          <Button type="submit" fullWidth variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Zaloguj'}
          </Button>
          {/* Forgot Password Link */}
          <Typography
            variant="body2"
            align="center"
            sx={{ cursor: 'pointer', textDecoration: 'underline', mt: 1 }}
            onClick={handleOpenModal}
          >
            Zapomniałem hasła
          </Typography>
        </Box>
      </Card>

      {/* Password Reset Modal */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Resetowanie hasła
            </Typography>
            <TextField
              fullWidth
              label="Adres email"
              variant="outlined"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              autoComplete="email"
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleResetPassword} fullWidth>
              {isLoadingReset ? <CircularProgress size={24} /> : 'Zresetuj hasło'}
            </Button>
            {resetInfo && (
              <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
                {resetInfo}
              </Typography>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
