import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { QueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { ReportPriceSummary } from 'src/pages/report-preview/report-summary/ReportPriceSummary';
import { BIKReportType } from 'src/types/BIKReport.type';
import { checkJWTValidity, isUserLogged, logoutUser } from '../PrivateRoute';
import { DesktopBox, Logo, MobileBox, MobileContent, StyledToolbar, ToolbarContainer } from './AppToolbar.styles';

type ToolbarContent = {
  id: string;
  text: string;
  customNavigation?: (navigate: ReturnType<typeof useNavigate>) => void;
};

type ToolbarType = 'main' | 'back';

const queryClient = new QueryClient();

const getReportUrl = (pathname: string) => {
  const params = pathname.split('/');
  const isProcess = params[params.length - 1] === 'process';
  params[params.length - 1] = isProcess ? 'old-process' : 'process';
  return params.join('/');
};

const getContent = (location: Location<any>): Record<ToolbarType, ToolbarContent[]> => {
  const backNavigation = [
    { id: 'back', text: 'Powrót do strony głównej', customNavigation: () => (window.location.href = '/') },
    import.meta.env.VITE_ENV === 'DEV' &&
      location.pathname.endsWith('process') && {
        id: 'back',
        text: 'Zmień typ raportu',
        customNavigation: () => (window.location.href = getReportUrl(location.pathname)),
      },
    isUserLogged() && {
      id: 'logout',
      text: 'Wyloguj',
      customNavigation: (navigate: NavigateFunction) => {
        logoutUser();
        queryClient.removeQueries();

        navigate('/sign-in?message=Zostałeś pomyślnie wylogowany.');
      },
    },
  ].filter(Boolean) as ToolbarContent[];

  const mainNavigation: ToolbarContent[] = [
    { id: 'about-us', text: 'O nas' },
    {
      id: 'check',
      text: 'Sprawdź swój BIK',
      customNavigation: (navigate: NavigateFunction) => navigate('/process-bik'),
    },
    { id: 'highlights', text: 'Jak wygląda nasz proces?' },
    { id: 'services', text: 'Dlaczego BIKfirmowy.pl?' },
    { id: 'faq', text: 'FAQ', customNavigation: (navigate: NavigateFunction) => navigate('/faq') },
    {
      id: 'partner-program',
      text: 'Program partnerski',
      customNavigation: (navigate: NavigateFunction) => navigate('/dashboard'),
    },
  ].filter(Boolean) as ToolbarContent[];

  return {
    main: mainNavigation,
    back: backNavigation,
  };
};

function AppToolbar({
  type,
  selectedRows,
  price,
  selectedPromoCode,
}: {
  type: ToolbarType;
  selectedRows?: number;
  selectedPromoCode?: { code: string; value: number };
  price?: Record<BIKReportType, number>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const content = useMemo(() => getContent(location), [location]);

  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      window.scrollTo({ top: targetScroll, behavior: 'smooth' });
      setOpen(false);
    }
  };
  useEffect(() => {
    checkJWTValidity();

    const authCheckInterval = setInterval(() => {
      checkJWTValidity();
    }, 10 * 60000);

    return () => clearInterval(authCheckInterval);
  }, []);

  if (!content[type].length) return null;

  const renderMenuItems = (items: ToolbarContent[]) =>
    items.map((item, key) => (
      <MenuItem
        key={key}
        onClick={item.customNavigation ? () => item.customNavigation!(navigate) : () => scrollToSection(item.id)}
        sx={{ py: '6px', px: '12px' }}
      >
        <Typography variant="body2" color="text.primary">
          {item.text}
        </Typography>
      </MenuItem>
    ));

  return (
    <ToolbarContainer>
      <Container maxWidth="lg">
        <StyledToolbar variant="regular">
          <DesktopBox>
            <Logo src="/logo.png" alt="BIK Logo" />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{renderMenuItems(content[type])}</Box>
            <ReportPriceSummary
              priceByType={price}
              selectedPromoCode={selectedPromoCode}
              selectedRowsCount={selectedRows}
            />
          </DesktopBox>

          {/* Mobile Menu */}
          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <MobileBox>
                <MobileContent>{renderMenuItems(content[type])}</MobileContent>
              </MobileBox>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </ToolbarContainer>
  );
}

export default AppToolbar;
