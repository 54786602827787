import { Box, DialogContentText, FormControlLabel, styled, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKCompany, BIKItem, BIKPrivate, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { SelectedRow } from 'src/types/SelectedRow';

const Item = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#ffffffe6',
  textAlign: 'center',
  borderRadius: '8px',
  border: '1px solid #e8e8e88a',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const PRICES = {
  openItemPrice: 299,
  closedItemPrice: 199,
  bikRequest: 99,
};

const forbiddenProps = ['$longestDebt', '$isRemovable'];
const GridSection = styled(Grid, { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $longestDebt?: number;
  $isRemovable: boolean;
}>(({ $longestDebt, $isRemovable }) => ({
  borderRadius: '8px',
  border: '2px solid #00000014',
  padding: '16px',
  backgroundColor: getDebtColor($longestDebt),
  transition: $isRemovable ? 'transform 0.3s ease-in-out' : '',
  cursor: $isRemovable ? 'pointer' : '',
  '&:hover': $isRemovable
    ? {
        transform: 'scale(1.02)',
      }
    : {},
}));

const getDebtColor = (longestDebt?: number) => {
  if (!longestDebt && longestDebt !== 0) {
    return '#79c44b29';
  }

  if (longestDebt > 0 && longestDebt <= 30) {
    return '#fbb14b29';
  }

  if (longestDebt > 30 && longestDebt <= 60) {
    return '#e95c5629';
  }

  if (longestDebt > 60) {
    return '#80808078';
  }

  return '#79c44b29';
};

type BIKItemRow = BIKItem & { price: number; sequenceNumber: number };
type BIKRequestRow = BIKRequest & { price: number; sequenceNumber: number };

function OldBIKReportContent({
  data,
  type,
  isSelectedAll,
  onSelectedRows,
}: {
  data: BIKCompany | BIKPrivate;
  type: BIKReportType;
  isSelectedAll: boolean;
  onSelectedRows: (itemsIndex: SelectedRow[], requestsIndex: SelectedRow[], type: BIKReportType) => void;
}) {
  const [checkedRequests, setCheckedRequests] = useState<SelectedRow[]>([]);
  const [checkedItems, setCheckedItems] = useState<SelectedRow[]>([]);
  const [isSelectAllItemsChecked, setIsSelectAllItemsChecked] = useState(isSelectedAll);
  const [isSelectAllRequestsChecked, setIsSelectAllRequestsChecked] = useState(isSelectedAll);

  const handleCheckboxItemsChange = (bikItem: BIKItemRow, index: number) => {
    if (!!checkedItems.find((item) => item.id === index)) {
      setCheckedItems(checkedItems.filter((i) => i.id !== index));
    } else {
      setCheckedItems([
        ...checkedItems,
        {
          id: index,
          bikReportType: type,
          price: bikItem.price,
          longestDebt: bikItem.longestDebt,
          longestDebtAt: bikItem.longestDebtAt,
          creditorId: bikItem.creditorId,
          signedOn: bikItem.signedOn,
        },
      ]);
    }
    setIsSelectAllItemsChecked(false);
  };

  const handleCheckboxRequestsChange = (bikRequest: BIKRequestRow, index: number) => {
    if (!!checkedRequests.find((item) => item.id === index)) {
      setCheckedRequests(checkedRequests.filter((i) => i.id !== index));
    } else {
      setCheckedRequests([
        ...checkedRequests,
        {
          id: index,
          bikReportType: type,
          price: bikRequest.price,
          creditorId: bikRequest.creditorId,
          signedOn: bikRequest.date,
        },
      ]);
    }

    setIsSelectAllRequestsChecked(false);
  };

  const openItems: BIKItemRow[] = useMemo(
    () =>
      data.items
        .filter((item) => item.isOpen)
        .map((item, key) => ({ ...item, price: PRICES.openItemPrice, sequenceNumber: key })),
    [data.items]
  );
  const closedItems: BIKItemRow[] = useMemo(() => {
    const openItemsLength = openItems.length;

    return data.items
      .filter((item) => !item.isOpen)
      .map((item, key) => {
        return {
          ...item,
          price: PRICES.closedItemPrice,
          sequenceNumber: key + openItemsLength,
        };
      });
  }, [data.items, openItems]);

  const requests: BIKRequestRow[] = useMemo(() => {
    return data.requests.map((item, key) => {
      return {
        ...item,
        price: PRICES.bikRequest,
        sequenceNumber: key,
      };
    });
  }, [data.requests]);

  const onChangeAllItems = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableOpenItems: SelectedRow[] = openItems
        .filter((item) => item.enableToRemove)
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          longestDebt: item.longestDebt,
          longestDebtAt: item.longestDebtAt,
          creditorId: item.creditorId,
          signedOn: item.signedOn,
        }));

      const allSelectableClosedItems: SelectedRow[] = closedItems
        .filter((item) => item.enableToRemove)
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          longestDebt: item.longestDebt,
          longestDebtAt: item.longestDebtAt,
          creditorId: item.creditorId,
          signedOn: item.signedOn,
        }));

      if (isSelectedAll !== undefined) {
        setIsSelectAllItemsChecked(isSelectedAll);
        setCheckedItems(isSelectedAll ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
        return;
      }

      setIsSelectAllItemsChecked(!isSelectAllItemsChecked);
      setCheckedItems(!isSelectAllItemsChecked ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
    },
    [openItems, closedItems, checkedItems, isSelectAllItemsChecked, type]
  );

  const onChangeAllRequests = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableRequests: SelectedRow[] = requests
        .filter((item) => item.enableToRemove)
        .map((item) => ({
          id: item.sequenceNumber,
          price: item.price,
          bikReportType: type,
          creditorId: item.creditorId,
          signedOn: item.date,
        }));

      if (isSelectedAll !== undefined) {
        setIsSelectAllRequestsChecked(isSelectedAll);
        setCheckedRequests(isSelectedAll ? allSelectableRequests : []);
        return;
      }

      setIsSelectAllRequestsChecked(!isSelectAllRequestsChecked);
      setCheckedRequests(!isSelectAllRequestsChecked ? allSelectableRequests : []);
    },
    [requests, checkedRequests, isSelectAllRequestsChecked, type]
  );

  useEffect(() => {
    if (type === 'COMPANY') {
      onChangeAllItems(isSelectedAll);
      onChangeAllRequests(isSelectedAll);
    } else {
      setTimeout(() => {
        onChangeAllItems(isSelectedAll);
        onChangeAllRequests(isSelectedAll);
      }, 100);
    }
  }, [isSelectedAll, type]);

  useEffect(() => {
    onSelectedRows(checkedItems, checkedRequests, type);
  }, [checkedRequests, checkedItems, isSelectedAll]);

  return (
    <DialogContentText
      component="div"
      sx={{
        backgroundImage: () => `url(/${type}-bg.png)`,
        backgroundSize: 'contain',
      }}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Item>
            <Typography variant="h4" gutterBottom>
              Wynik raportu BIK {type === 'CUSTOMER' ? 'Prywatnego' : 'Firmowego'}
            </Typography>
          </Item>
        </Grid>

        {/* Informacje ogólne */}
        <Grid size={{ xs: 6 }}>
          <Item>
            <Typography variant="body1">
              <b>Nazwa firmy:</b> {data.name}
            </Typography>
          </Item>
        </Grid>

        <Grid size={{ xs: 6 }}>
          {'nip' in data && (
            <Item>
              <Typography variant="body1">
                <b>NIP:</b> {data.nip}
              </Typography>
            </Item>
          )}
          {'pesel' in data && (
            <Item>
              <Typography variant="body1">
                <b>PESEL:</b> {data.pesel || 'brak danych'}
              </Typography>
            </Item>
          )}
        </Grid>

        {!!data?.items?.length && (
          <>
            <Grid size={{ xs: 12 }}>
              <Item>
                <Typography variant="h5">Zobowiązania kredytowe</Typography>
              </Item>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Item>
                <Typography variant="body1">
                  Rekomendowana ilość do usunięcia: {data.items.filter((item) => item?.enableToRemove).length}
                  {!!data.items.filter((item) => item?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllItems()}
                    >
                      <b>{isSelectAllItemsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </Item>
            </Grid>

            {!!openItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">Zobowiązania kredytowe w trakcie spłaty:</Typography>
                </Grid>
                {openItems.map((item) => (
                  <BankItemComponent
                    item={item}
                    index={item.sequenceNumber}
                    key={item.sequenceNumber}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.sequenceNumber)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}

            {!!closedItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">
                    Zobowiązania kredytowe - zamknięte (w ciągu ostatnich 60 miesięcy):
                  </Typography>
                </Grid>
                {closedItems.map((item) => (
                  <BankItemComponent
                    item={item}
                    index={item.sequenceNumber}
                    key={item.sequenceNumber}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.sequenceNumber)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}
          </>
        )}

        {/* Sekcja zapytań do banków */}
        {!!requests.length && (
          <>
            <Grid size={{ xs: 12 }}>
              <Item>
                <Typography variant="h5">Zapytania kredytowe do BIK:</Typography>
              </Item>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Item>
                <Typography variant="body1">
                  Rekomendowana ilość do usunięcia: {requests.filter((request) => request?.enableToRemove).length}
                  {!!requests.filter((request) => request?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllRequests()}
                    >
                      <b>{isSelectAllRequestsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </Item>
            </Grid>
            {requests.map((request) => (
              <BankRequestComponent
                index={request.sequenceNumber}
                request={request}
                key={request.sequenceNumber}
                isChecked={!!checkedRequests.find((item) => item.id === request.sequenceNumber)}
                onCheckboxChange={handleCheckboxRequestsChange}
              />
            ))}
          </>
        )}
      </Grid>
    </DialogContentText>
  );
}

const BankRequestComponent = ({
  request,
  index,
  isChecked,
  onCheckboxChange,
}: {
  request: BIKRequestRow;
  index: number;
  isChecked: boolean;
  onCheckboxChange: (bikRequest: BIKRequestRow, index: number) => void;
}) => {
  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString('pl-PL');

  return (
    <GridSection
      container
      key={index}
      spacing={2}
      size={{ xs: 12 }}
      sx={{
        borderColor: isChecked ? '#3daf3d63' : '#00000014',
      }}
      $isRemovable={request.enableToRemove}
      onClick={() => request.enableToRemove && onCheckboxChange(request, index)}
    >
      <Grid size={{ xs: request.enableToRemove ? 10 : 12 }}>
        <Item>
          <Typography variant="body1">
            <b>
              {index + 1} - {request.companyName}
            </b>
          </Typography>
        </Item>
      </Grid>
      {request.enableToRemove && (
        <Grid
          size={{ xs: 2 }}
          sx={{
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography style={{ marginBottom: 4, fontWeight: 'bold', fontSize: '13px' }}>
                  USUWAM - {request.price} zł
                </Typography>
                <Switch
                  disabled={!request.enableToRemove}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(request, index)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${index}` }}
                />
              </Box>
            }
            label=""
          />
        </Grid>
      )}
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Data zapytania: {formatDate(request.date)}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>{request.commitment}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Czy można usunąć? {request.enableToRemove ? 'Tak' : 'Nie'}</Item>
      </Grid>
    </GridSection>
  );
};

const BankItemComponent = ({
  item,
  index,
  isChecked,
  onCheckboxChange,
}: {
  item: BIKItemRow;
  index: number;
  isChecked: boolean;
  onCheckboxChange: (bikItem: BIKItemRow, index: number) => void;
}) => {
  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString('pl-PL');

  return (
    <GridSection
      $longestDebt={item.longestDebt}
      container
      key={index}
      spacing={2}
      sx={{
        borderColor: isChecked ? '#3daf3d63' : '#00000014',
      }}
      $isRemovable={item.enableToRemove}
      onClick={() => item.enableToRemove && onCheckboxChange(item, index)}
    >
      <Grid size={{ xs: item.enableToRemove ? 7 : 9 }}>
        <Item>
          <Typography variant="body1">
            <b>
              {index + 1} - {item.subject}
            </b>
          </Typography>
        </Item>
      </Grid>

      <Grid size={{ xs: 3 }}>
        <Item>
          <Typography variant="body1">
            <b>Typ zapytania:</b> {item.type}
          </Typography>
        </Item>
      </Grid>

      {item.enableToRemove && (
        <Grid
          size={{ xs: 2 }}
          sx={{
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography style={{ marginBottom: 4, fontWeight: 'bold', fontSize: '13px' }}>
                  USUWAM - {item.price} zł
                </Typography>
                <Switch
                  disabled={!item.enableToRemove}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(item, index)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${index}` }}
                />
              </Box>
            }
            label=""
          />
        </Grid>
      )}

      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Typ kredytu: {item.typeDescription}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Najdłuższe zadłużenie: {item.longestDebt}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Czy kredyt jest otwarty? {item.isOpen ? 'Tak' : 'Nie'}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Status kredytu: {item.status}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Data podpisania: {formatDate(item.signedOn)}</Item>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Item>Czy można usunąć? {item.enableToRemove ? 'Tak' : 'Nie'}</Item>
      </Grid>
    </GridSection>
  );
};

export default memo(OldBIKReportContent);
