import { Box, Button, TextField, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { PromoCodeType } from 'src/types/PromoCode.type';

const PROMO_CODES: Record<string, number> = {
  JESIEN5: 0.05,
  JESIEN10: 0.1,
  JESIEN15: 0.15,
  JESIEN20: 0.2,
  JESIEN25: 0.25,
  JESIENMAX: 1999,
};

export const PROMO_ERROR_MESSAGE = 'Adnotacja do raportu nie istnieje';

type PromoCodeProps = {
  setSelectedPromoCode: (promoCode: PromoCodeType) => void;
  selectedRowsCount: number;
};

export const PromoCode = ({ selectedRowsCount, setSelectedPromoCode }: PromoCodeProps) => {
  const [promoCodeInfo, setPromoCodeInfo] = useState('');

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [promoCode, setPromoCode] = useState('');

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handleApplyPromoCode = () => {
    const value = PROMO_CODES?.[promoCode.toUpperCase()];

    if (!value) {
      setPromoCodeInfo(PROMO_ERROR_MESSAGE);
      return;
    }

    setSelectedPromoCode({
      code: promoCode.toUpperCase(),
      value,
    });

    setPromoCodeInfo(`Aktywowano adnotację do raportu ${promoCode.toUpperCase()}`);
  };

  const isPromoError = promoCodeInfo === PROMO_ERROR_MESSAGE;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '10px',
        alignItems: isMobile ? 'stretch' : 'center',
        width: isMobile ? '100%' : 'auto',
      }}
    >
      <TextField
        label="Adnotacja do raportu"
        variant="outlined"
        size="small"
        error={isPromoError}
        helperText={promoCodeInfo || ' '}
        value={promoCode}
        onChange={handlePromoCodeChange}
        fullWidth={isMobile}
        slotProps={{
          formHelperText: {
            style: {
              margin: 0,
              height: 0,
            },
          },
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyPromoCode}
        disabled={!promoCode || !selectedRowsCount}
        fullWidth={isMobile}
        sx={{
          ':disabled': {
            background: '#80808099',
          },
          fontSize: '16px',
          borderRadius: '8px',
        }}
      >
        Zatwierdź adnotacje
      </Button>
    </Box>
  );
};
