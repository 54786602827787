import { Box, Button, Switch, Theme, Typography, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { chooseEntriesToRemove, chooseRequestsEntriesToRemove, updateBIKPrices, useBIKReports } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Footer from 'src/components/Footer';
import { LoadingScreen } from 'src/components/LoadingScreen';
import OldBIKReportContent from 'src/components/OldBIKReportContent';
import ScrollToTop from 'src/components/ScrollToTop';
import { PromoCode } from 'src/pages/report-preview/report-summary/PromoCode';
import { BIKReportType } from 'src/types/BIKReport.type';
import { SelectedRow } from 'src/types/SelectedRow';
import { calculateFinalPrice } from 'src/utils/calculate';
import { handleElementGrammar } from 'src/utils/grammar';
import BIKNotFound from './commonSites/BIKNotFound';
import LoadingSite from './commonSites/LoadingSite';

type RowData = Record<
  BIKReportType,
  {
    items: SelectedRow[];
    requests: SelectedRow[];
    totalNumber: number;
  }
>;

const SummaryComponent = () => {
  return (
    <Box
      sx={{
        padding: '4px',
        backgroundColor: '#e0f7fa',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#00695f',
        }}
      >
        Podsumowanie raportu BIK
      </Typography>
    </Box>
  );
};
const SelectAllComponent = ({
  isSelectedAll,
  setIsSelectedAll,
}: {
  isSelectedAll: boolean;
  setIsSelectedAll: (isSelectedAll: boolean) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        flexWrap: 'wrap',
        height: '100%',
        minHeight: '50px',
        cursor: 'pointer',
      }}
      onClick={() => setIsSelectedAll(!isSelectedAll)}
    >
      <Typography variant="body1" sx={{ fontWeight: 500, marginRight: '10px' }}>
        {isSelectedAll ? 'Odznacz' : 'Zaznacz'} wszystkie możliwe pozycje
      </Typography>

      <Switch
        checked={isSelectedAll}
        color="primary"
        sx={{
          '.MuiSwitch-thumb': {
            color: isSelectedAll ? '#4caf50' : '#f44336',
          },
        }}
      />
    </Box>
  );
};

export default function ShowBIKRaport() {
  const navigate = useNavigate();
  const params = useParams();
  const [isBIKLoading, setIsBIKLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalPrice, setTotalPrice] = useState<Record<BIKReportType, number>>({
    COMPANY: 0,
    CUSTOMER: 0,
  });
  const [selectedRows, setSelectedRows] = useState<RowData>({
    COMPANY: { items: [], requests: [], totalNumber: 0 },
    CUSTOMER: { items: [], requests: [], totalNumber: 0 },
  });
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState({ code: '', value: 0 });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { data, isLoading } = useBIKReports(params?.id ?? 'Wrong_param');

  const handleSubmitSelectedRows = useCallback(async () => {
    setIsBIKLoading(true);

    if (!data?.id) {
      return;
    }

    try {
      const itemsToSend: Omit<SelectedRow, 'id' | 'price'>[] = [
        ...selectedRows.COMPANY.items,
        ...selectedRows.CUSTOMER.items,
      ].map((item) => ({
        creditorId: item.creditorId,
        bikReportType: item.bikReportType,
        longestDebt: item.longestDebt,
        longestDebtAt: item.longestDebtAt,
        signedOn: item.signedOn,
      }));

      if (itemsToSend.length) {
        await chooseEntriesToRemove(data.id, itemsToSend);
      }

      const requestsToSend: Omit<SelectedRow, 'id' | 'price'>[] = [
        ...selectedRows.COMPANY.requests,
        ...selectedRows.CUSTOMER.requests,
      ].map((request) => ({
        creditorId: request.creditorId,
        bikReportType: request.bikReportType,
        signedOn: request.signedOn,
      }));

      if (requestsToSend.length) {
        await chooseRequestsEntriesToRemove(data.id, requestsToSend);
      }

      await updateBIKPrices(data.id, {
        price: totalPrice.COMPANY + totalPrice.CUSTOMER,
        discountCode: selectedPromoCode.code,
        discountPrice: calculateFinalPrice(selectedPromoCode, totalPrice),
      });

      navigate(`/process-bik/${data.id}/add-details`);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsBIKLoading(false);
    }
  }, [selectedRows, totalPrice, selectedPromoCode, data?.id]);

  const handleClose = () => {
    setError(null);
  };

  const onSelectedRows = useCallback(
    (itemsIndex: SelectedRow[], requetsIndex: SelectedRow[], type: BIKReportType) => {
      setTotalPrice({
        ...totalPrice,
        [type]:
          itemsIndex.reduce((prevItem, currItem) => prevItem + currItem.price, 0) +
          requetsIndex.reduce((prevRequest, currRequest) => prevRequest + currRequest.price, 0),
      });
      setSelectedRows({
        ...selectedRows,
        [type]: {
          items: itemsIndex,
          requests: requetsIndex,
          totalNumber: itemsIndex.length + requetsIndex.length,
        },
      });
    },
    [totalPrice, selectedRows]
  );

  if (isLoading) {
    return <LoadingSite />;
  }

  if (!data?.id) {
    return <BIKNotFound reportData={data} />;
  }

  const elements = selectedRows.COMPANY.totalNumber + selectedRows.CUSTOMER.totalNumber;

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" selectedRows={elements} price={totalPrice} selectedPromoCode={selectedPromoCode} />
      <LoadingScreen
        loadingMessage="Dodawanie wybranych pozycji w toku..."
        open={isBIKLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
      <Container
        id="show-bik-raport"
        sx={{
          pt: { xs: 12, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 4 },
        }}
      >
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, sm: 12, md: 8 }}>
            <SummaryComponent />
          </Grid>

          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <SelectAllComponent isSelectedAll={isSelectedAll} setIsSelectedAll={setIsSelectedAll} />
          </Grid>
        </Grid>
        {data.company && (
          <OldBIKReportContent
            data={data.company}
            type="COMPANY"
            onSelectedRows={onSelectedRows}
            isSelectedAll={isSelectedAll}
          />
        )}
        {data.customer && (
          <OldBIKReportContent
            data={data.customer}
            type="CUSTOMER"
            onSelectedRows={onSelectedRows}
            isSelectedAll={isSelectedAll}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            width: '100%',
            gap: isMobile ? '10px' : 0,
          }}
        >
          <PromoCode
            selectedRowsCount={selectedRows.COMPANY.totalNumber + selectedRows.CUSTOMER.totalNumber}
            setSelectedPromoCode={setSelectedPromoCode}
          />
          {/* Przycisk "Popraw BIK" */}
          <Button
            disabled={!selectedRows.COMPANY.totalNumber && !selectedRows.CUSTOMER.totalNumber}
            onClick={handleSubmitSelectedRows}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              marginTop: isMobile ? 0 : 2,
              ':disabled': {
                background: '#80808099',
              },
              fontSize: '16px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
            }}
          >
            Popraw BIK
          </Button>
        </Box>
        {!!elements && totalPrice && (
          <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" fontSize={13}>
            <Typography color="text.primary" variant="body1">
              Wybrano{' '}
              <b>
                {selectedRows.COMPANY.totalNumber + selectedRows.CUSTOMER.totalNumber} {handleElementGrammar(elements)}
              </b>{' '}
              do usunięcia.
            </Typography>
            <Typography color="text.primary" variant="body1">
              Wartość usługi <b>{totalPrice.COMPANY + totalPrice.CUSTOMER} PLN.</b>
            </Typography>
            {!!selectedPromoCode.value && (
              <Typography color="text.primary" variant="body1">
                Po zastosowaniu kodu {selectedPromoCode.code} wartość usługi{' '}
                <b>{calculateFinalPrice(selectedPromoCode, totalPrice)} PLN.</b>
              </Typography>
            )}
          </Box>
        )}
      </Container>
      <Footer />
    </>
  );
}
