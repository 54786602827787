import { Box, Typography } from '@mui/material';
import { BIKReportCounterByType } from 'src/types/BIKReport.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { calculateFinalPrice } from 'src/utils/calculate';
import { handleElementGrammar } from 'src/utils/grammar';

type ReportPriceSummaryProps = {
  selectedPromoCode?: PromoCodeType;
  selectedRowsCount?: number;
  priceByType?: BIKReportCounterByType;
};

export const ReportPriceSummary: React.FC<ReportPriceSummaryProps> = ({
  selectedRowsCount,
  priceByType,
  selectedPromoCode,
}) => {
  if (!selectedRowsCount || !priceByType) {
    return null;
  }

  const totalPrice = priceByType.COMPANY + priceByType.CUSTOMER;
  const finalPrice = selectedPromoCode?.value ? calculateFinalPrice(selectedPromoCode, priceByType) : null;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" fontSize={13}>
      <Typography color="text.primary" variant="body1">
        Wybrano <b>{`${selectedRowsCount} ${handleElementGrammar(selectedRowsCount)} `}</b>
        do usunięcia.
      </Typography>

      <Typography color="text.primary" variant="body1">
        Wartość usługi: <b>{`${totalPrice} PLN`}</b>
      </Typography>

      {finalPrice && selectedPromoCode?.code && (
        <Typography color="text.primary" variant="body1">
          Po zastosowaniu kodu <b>{selectedPromoCode.code}</b> wartość usługi: <b>{`${finalPrice} PLN`}</b>
        </Typography>
      )}
    </Box>
  );
};
