import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { chooseEntriesToRemove, chooseRequestsEntriesToRemove, updateBIKPrices } from 'src/api/bikApi';
import { LoadingScreen } from 'src/components/LoadingScreen';
import { BIKReportCounterByType } from 'src/types/BIKReport.type';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { RowData } from 'src/types/RowData';
import { SelectedRow } from 'src/types/SelectedRow';
import { calculateFinalPrice } from 'src/utils/calculate';
import { PromoCode } from './PromoCode';
import { ReportPriceSummary } from './ReportPriceSummary';

type ReportSummaryProps = {
  data: CompanyBIKSummary;
  selectedRows: RowData;
  setSelectedPromoCode: (promoCode: PromoCodeType) => void;
  selectedPromoCode: PromoCodeType;
  selectedRowsCount: number;
  priceByType: BIKReportCounterByType;
};

export const ReportSummary = ({
  data,
  selectedRows,
  priceByType,
  selectedPromoCode,
  setSelectedPromoCode,
  selectedRowsCount,
}: ReportSummaryProps) => {
  const navigate = useNavigate();

  const [isBIKLoading, setIsBIKLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleClose = () => {
    setError(null);
  };

  const handleSubmitSelectedRows = useCallback(async () => {
    setIsBIKLoading(true);

    if (!data?.id) {
      return;
    }

    const itemsToSend: Omit<SelectedRow, 'id' | 'price'>[] = [
      ...selectedRows.COMPANY.items,
      ...selectedRows.CUSTOMER.items,
    ].map((item) => ({
      creditorId: item.creditorId,
      bikReportType: item.bikReportType,
      longestDebt: item.longestDebt,
      longestDebtAt: item.longestDebtAt,
      signedOn: item.signedOn,
    }));

    if (itemsToSend.length) {
      const response = await chooseEntriesToRemove(data.id, itemsToSend);

      if (response && response.status !== 204) {
        setIsBIKLoading(false);
        setError('Wystąpił błąd podczas dodawania pozycji do usunięcia.');
        return;
      }
    }

    const requestsToSend: Omit<SelectedRow, 'id' | 'price'>[] = [
      ...selectedRows.COMPANY.requests,
      ...selectedRows.CUSTOMER.requests,
    ].map((request) => ({
      creditorId: request.creditorId,
      bikReportType: request.bikReportType,
      signedOn: request.signedOn,
    }));

    if (requestsToSend.length) {
      const responseRequests = await chooseRequestsEntriesToRemove(data.id, requestsToSend);

      if (responseRequests && responseRequests.status !== 204) {
        setIsBIKLoading(false);
        setError('Wystąpił błąd podczas dodawania zapytań do BIK do usunięcia.');
        return;
      }
    }

    const responsePrices = await updateBIKPrices(data.id, {
      price: priceByType.COMPANY + priceByType.CUSTOMER,
      discountCode: selectedPromoCode.code,
      discountPrice: calculateFinalPrice(selectedPromoCode, priceByType),
    });

    if (responsePrices && responsePrices.status !== 204) {
      setIsBIKLoading(false);
      setError('Wystąpił błąd dodawania ceny do raportu');
      return;
    }

    navigate(`/process-bik/${data.id}/add-details`);
  }, [selectedRows, priceByType, selectedPromoCode, data?.id]);

  if (!priceByType || !selectedPromoCode) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
          width: '100%',
          gap: isMobile ? '10px' : 0,
        }}
      >
        <PromoCode selectedRowsCount={selectedRowsCount} setSelectedPromoCode={setSelectedPromoCode} />
        <Button
          disabled={!selectedRows.COMPANY.totalNumber && !selectedRows.CUSTOMER.totalNumber}
          onClick={handleSubmitSelectedRows}
          variant="contained"
          color="primary"
          size="large"
          sx={{
            marginTop: isMobile ? 0 : 2,
            ':disabled': {
              background: '#80808099',
            },
            fontSize: '16px',
            borderRadius: '8px',
            width: isMobile ? '100%' : 'auto',
          }}
        >
          Popraw BIK
        </Button>
      </Box>
      <ReportPriceSummary
        priceByType={priceByType}
        selectedPromoCode={selectedPromoCode}
        selectedRowsCount={selectedRowsCount}
      />

      <LoadingScreen
        loadingMessage="Dodawanie wybranych pozycji w toku..."
        open={isBIKLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
    </>
  );
};
