import { styled } from '@mui/system';
import { forwardRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ControllerRenderProps } from 'react-hook-form';
import { BIKUploadValidationSchema } from 'src/types/FormField.type';

const getColor = (props: { acceptedFiles: File[]; isDragAccept: any; isDragReject: any; isFocused: any }) => {
  if (props?.acceptedFiles?.length) {
    return '#074fff !important';
  }

  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const getBackgroundColor = (props: { acceptedFiles: File[] }) => {
  if (props?.acceptedFiles?.length) {
    return '#196aff17';
  }

  return '#fafafa';
};

const Container = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  color: ${(props: any) => getColor(props)};
  border-style: dashed;
  background-color: ${(props: any) => getBackgroundColor(props)};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const maxFileSize = 5242880;

export const PdfDragNDrop = forwardRef((field: ControllerRenderProps<BIKUploadValidationSchema>, ...props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: maxFileSize,
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (files, fileRejections) => {
      setErrorMessage('');

      if (files.length) {
        field.onChange(files[0]);
      }

      if (fileRejections.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              setErrorMessage(`Błąd: rozmiar pliku jest za duży. (Plik jest większy niż ${maxFileSize / 1000000} Mb)`);
            }

            if (err.code === 'file-invalid-type') {
              setErrorMessage(`Błąd: nieobsługiwany rozmiar pliku. Dodaj plik *.pdf`);
            }

            if (err.code === 'too-many-files') {
              setErrorMessage(`Błąd: możesz wrzucić tylko jeden plik naraz.`);
            }
          });
        });
      }
    },
  });

  const acceptedFileItems = acceptedFiles.map((file: any) => `${file.path} - ${file.size} bytes`);

  return (
    <section className="container">
      <Container {...props} {...getRootProps({ isFocused, isDragAccept, isDragReject, acceptedFiles })}>
        <input {...getInputProps()} />
        {acceptedFileItems.length ? (
          <>
            <p>Plik został pomyślnie dodany! - {acceptedFileItems[0]}</p>
            <em>(Limit 1 plik)</em>
          </>
        ) : (
          <>
            <p>Klknij i wybierz BIK lub przenieś plik widoczne pole</p>
            <em>(Limit 1 plik)</em>
          </>
        )}
      </Container>
      <aside style={{ color: 'red' }}>{errorMessage ?? <h4 style={{ color: 'red' }}>{errorMessage}</h4>}</aside>
    </section>
  );
});
