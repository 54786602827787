import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendEntriesToRemove } from 'src/api/bikApi';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import AppToolbar from '../components/app-toolbar/AppToolbar';
import LoadingSite from './commonSites/LoadingSite';

let isSent = false;
export default function ThankYou() {
  const params = useParams();
  const [finishInfo, setFinishInfo] = useState('loading');

  useEffect(() => {
    const { id } = params;

    if (!id || isSent) {
      return;
    }

    isSent = true;

    sendEntriesToRemove(id);
    setFinishInfo('success');
  }, [params]);

  if (finishInfo === 'loading') {
    return <LoadingSite />;
  }

  if (finishInfo === 'error') {
    return (
      <>
        <ScrollToTop />
        <AppToolbar type="back" />
        <Container
          id="thank-you"
          sx={{
            pt: { xs: 4, sm: 12 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white">
            <Card sx={{ maxWidth: 600, padding: 4, borderRadius: 4, boxShadow: 3 }}>
              <CardContent>
                {/* Error icon */}
                <Box display="flex" justifyContent="center" mb={2}>
                  <CancelIcon sx={{ fontSize: 60, color: 'red' }} />
                </Box>

                {/* Error message */}
                <Typography variant="h5" align="center" gutterBottom>
                  Przepraszamy, wystąpił błąd
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1" align="justify" paragraph>
                  Niestety, nie udało się przetworzyć Twojego żądania. Prosimy spróbować ponownie później lub
                  skontaktować się z naszym zespołem wsparcia, aby uzyskać pomoc.
                </Typography>
                <Typography variant="body1" align="justify" paragraph>
                  Wystąpił błąd podczas przetwarzania dokumentów BIK. Sprawdź swoje połączenie internetowe i spróbuj
                  ponownie. Jeśli problem się powtarza, skontaktuj się z naszym wsparciem.
                </Typography>

                {/* Signature */}
                <Box mt={4} textAlign="right">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Zespół BIK Firmowy
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />
      <Container
        id="thank-you"
        sx={{
          pt: { xs: 4, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white">
          <Card sx={{ maxWidth: 600, padding: 4, borderRadius: 4, boxShadow: 3 }}>
            <CardContent>
              {/* Success icon */}
              <Box display="flex" justifyContent="center" mb={2}>
                <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
              </Box>

              {/* Success message */}
              <Typography variant="h5" align="center" gutterBottom>
                Dziękujemy za wybór naszych usług!
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" align="justify" paragraph>
                Twoje spersonalizowane dokumenty, umożliwiające korektę rejestru BIK, są już gotowe. Nasz Ekspert
                skontaktuje się z Tobą w ciągu 24 godzin i pomoże Ci w finalizacji transakcji, dokonaniu płatności oraz
                udzieli szczegółowych instrukcji dotyczących dalszych działań związanych z dokumentami.
              </Typography>
              <Typography variant="body1" align="justify" paragraph>
                Po zarejestrowaniu płatności, otrzymasz na swój adres e-mail spersonalizowane dokumenty, które należy
                podpisać i odesłać zgodnie z załączonymi instrukcjami. Dokładamy wszelkich starań, aby nasze dokumenty
                były jak najbardziej aktualne, jednak prosimy mieć na uwadze, że instytucje finansowe, w tym banki, mogą
                zmieniać swoje procedury, co czasem skutkuje odrzuceniem wniosku. W takim przypadku bank przekaże Ci
                informacje o koniecznych poprawkach lub dalszych krokach, aby wniosek został zaakceptowany.
              </Typography>
              <Typography variant="body1" align="justify" paragraph>
                Prosimy o przesłanie zeskanowanych dokumentów w odpowiedzi na nasz e-mail, abyśmy mogli kontynuować
                proces i zapewnić dalsze wsparcie.
              </Typography>

              {/* Signature */}
              <Box mt={4} textAlign="right">
                <Typography variant="subtitle1" fontWeight="bold">
                  Zespół BIK Firmowy
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
