import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Snackbar, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import React, { useState } from 'react';

// Component for the Partner Link Section with enhanced responsiveness
const PartnerLinkSection: React.FC<{ partnerLink: string }> = ({ partnerLink }) => {
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Function to copy link to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(partnerLink).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        p: 1, // Adjust padding for mobile and desktop
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Column layout for mobile, row for desktop
        alignItems: 'center',
        justifyContent: { xs: 'flex-start', sm: 'center' },
        width: { xs: '100%', sm: '60%' }, // Take full width of parent container
        maxWidth: { xs: '100%', sm: '100%' }, // Ensure full width on smaller screens
        overflow: 'hidden', // Prevent overflow
        textAlign: { xs: 'center', sm: 'left' },
        margin: '0 auto', // Center the box within its parent container
        boxSizing: 'border-box', // Include border in the element's total width and height
      }}
    >
      {/* Link title */}
      <Typography variant="subtitle2" sx={{ fontWeight: 500, mb: { xs: 1, sm: 0 } }}>
        Link polecający:
      </Typography>

      {/* Link display box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on desktop
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', sm: 'auto' }, // Full width on mobile, auto for desktop
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            bgcolor: alpha(theme.palette.primary.light, 0.2),
            borderRadius: 1,
            p: 1,
            fontFamily: 'monospace',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            width: { xs: '90vw', sm: 'auto' }, // Restrict width on mobile to 90% of viewport
            maxWidth: '100%', // Prevent overflow on both mobile and desktop
          }}
        >
          {partnerLink}
        </Typography>

        {/* Copy to Clipboard Button */}
        <Tooltip title="Kopiuj link do schowka">
          <IconButton
            onClick={handleCopyToClipboard}
            sx={{
              color: theme.palette.primary.main,
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              borderRadius: '50%',
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.2),
              },
              ml: { xs: 0, sm: 1 }, // No margin on mobile, margin-left on desktop
              mt: { xs: 1, sm: 0 }, // Margin-top for mobile to create spacing
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Pomyślnie skopiowano link do schowka."
      />
    </Box>
  );
};

export default PartnerLinkSection;
