import Container from '@mui/material/Container';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendContactRequest, useBIKReports } from 'src/api/bikApi';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import BIKReportContent from 'src/components/bik-report/BIKReportContent';
import CommunicationPreferencesModal from 'src/components/CommunicationPreferencesModal';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import { ReportSummary } from 'src/pages/report-preview/report-summary/ReportSummary';
import { SummaryTopTitle } from 'src/pages/report-preview/report-summary/SummaryTopTitle';
import { BIKReportCounterByType, BIKReportType } from 'src/types/BIKReport.type';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { BIKCompany, BIKPrivate } from 'src/types/CompanyBIKSummary.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { RowData } from 'src/types/RowData';
import { SelectedRow } from 'src/types/SelectedRow';
import BIKNotFound from './commonSites/BIKNotFound';
import LoadingSite from './commonSites/LoadingSite';

export default function BIKReportPreview() {
  const params = useParams();
  const { data, isLoading, isError, error } = useBIKReports(params?.id ?? 'Wrong_param');
  const [failedReportId, setFailedReportId] = useState(params?.id);
  const [isCustomerRequestLoading, setIsCustomerRequestLoading] = useState(false);

  const [totalPrice, setTotalPrice] = useState<BIKReportCounterByType>({ COMPANY: 0, CUSTOMER: 0 });
  const [selectedRows, setSelectedRows] = useState<RowData>({
    COMPANY: { items: [], requests: [], totalNumber: 0 },
    CUSTOMER: { items: [], requests: [], totalNumber: 0 },
  });
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCodeType>({ code: '', value: 0 });

  // Memoized selected rows count
  const selectedRowsCount = useMemo(
    () => selectedRows.COMPANY.totalNumber + selectedRows.CUSTOMER.totalNumber,
    [selectedRows]
  );

  const handleClosePreferences = () => {
    setFailedReportId('');
  };

  const handlePreferencesSubmit = async (data: CommunicationPreferences) => {
    if (!failedReportId) {
      setFailedReportId('');
      return false;
    }
    setIsCustomerRequestLoading(true);

    try {
      await sendContactRequest(failedReportId, data);
    } finally {
      setFailedReportId('');
      setIsCustomerRequestLoading(false);
    }

    return true;
  };

  // Memoized handler to calculate selected rows and prices
  const onSelectedRows = useCallback((itemsIndex: SelectedRow[], requestsIndex: SelectedRow[], type: BIKReportType) => {
    const totalPriceForType =
      itemsIndex.reduce((prev, curr) => prev + curr.price, 0) +
      requestsIndex.reduce((prev, curr) => prev + curr.price, 0);

    setTotalPrice((prevPrice) => ({
      ...prevPrice,
      [type]: totalPriceForType,
    }));

    setSelectedRows((prevRows) => ({
      ...prevRows,
      [type]: {
        items: itemsIndex,
        requests: requestsIndex,
        totalNumber: itemsIndex.length + requestsIndex.length,
      },
    }));
  }, []);

  // Early return for loading or data not found
  if (isLoading) return <LoadingSite />;
  if (!data?.id)
    return (
      <>
        <BIKNotFound reportData={data} />
        {(error as any)?.status !== 400 && (
          <CommunicationPreferencesModal
            isLoading={isCustomerRequestLoading}
            open={!!failedReportId && isError}
            onClose={handleClosePreferences}
            onSubmit={handlePreferencesSubmit}
          />
        )}
      </>
    );

  // Helper component to render BIK report content based on type
  const renderBIKReportContent = (dataItem: BIKCompany | BIKPrivate | undefined, type: BIKReportType) => {
    return dataItem ? (
      <BIKReportContent data={dataItem} type={type} onSelectedRows={onSelectedRows} isSelectedAll={isSelectedAll} />
    ) : null;
  };

  const { company, customer } = data;

  return (
    <>
      <ScrollToTop />
      <AppToolbar
        type="back"
        selectedRows={selectedRowsCount}
        price={totalPrice}
        selectedPromoCode={selectedPromoCode}
      />
      <Container
        id="show-bik-raport"
        sx={{
          pt: { xs: 12, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 4 },
        }}
      >
        {company && (
          <SummaryTopTitle
            type={'COMPANY'}
            isSelectedAll={isSelectedAll}
            setIsSelectedAll={setIsSelectedAll}
            showSelectAll
          />
        )}
        {renderBIKReportContent(company, 'COMPANY')}
        {customer && (
          <SummaryTopTitle
            showSelectAll={!company}
            type={'CUSTOMER'}
            isSelectedAll={isSelectedAll}
            setIsSelectedAll={setIsSelectedAll}
          />
        )}
        {renderBIKReportContent(customer, 'CUSTOMER')}

        <ReportSummary
          data={data}
          priceByType={totalPrice}
          selectedPromoCode={selectedPromoCode}
          selectedRows={selectedRows}
          selectedRowsCount={selectedRowsCount}
          setSelectedPromoCode={setSelectedPromoCode}
        />
      </Container>
      <Footer />
    </>
  );
}
