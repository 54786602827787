import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Błyskawiczne działanie',
    description:
      'Nasza zaawansowana technologia AI pozwala na natychmiastową analizę i przetwarzanie danych, dzięki czemu otrzymujesz rekomendacje w zaledwie 30 sekund. To najszybszy sposób na poprawę oceny BIK.',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Pełna przejrzystość',
    description:
      'Każdy krok w procesie jest całkowicie transparentny. Otrzymujesz szczegółowe wyjaśnienia i jasne rekomendacje, dzięki czemu zawsze wiesz, co się dzieje z Twoją oceną BIK.',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Skuteczność gwarantowana',
    description:
      'Dzięki precyzyjnej analizie opartej na AI, nasze rekomendacje i specjalistyczne dokumenty są niezwykle skuteczne. Twoja zdolność kredytowa poprawi się szybko, co otworzy przed Tobą nowe możliwości finansowe.',
  },
];

const carouselImg = ['before-bik.jpg', 'after-bik.jpg'];

export default function Services() {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [actualImg, setActualImg] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (carouselImg.length === actualImg + 1) {
        setActualImg(0);
      } else {
        setActualImg(actualImg + 1);
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [actualImg]);
  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="services" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Dlaczego{' '}
              <Typography
                component="span"
                variant="h2"
                sx={{
                  color: 'primary.main',
                }}
              >
                BIKfirmowy
              </Typography>
              .pl?
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 1, sm: 2 } }}>
              Nikt nie potrafi przeprocesować dokumentów i dostarczyć skutecznych rekomendacji oraz specjalistycznych
              dokumentów do poprawy oceny BIK tak szybko jak my.
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }}>
              Jako wiodąca firma technologiczna w poprawie oceny BIK, pomagamy klientom z różnych branż. Oferujemy
              wyjątkowo szybką i wygodną pomoc w odzyskiwaniu zdolności kredytowej, dzięki której firmy przywracają
              płynność finansową i mogą inwestować w rozwój lub nowe projekty.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: () => `url(${carouselImg[actualImg]})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 360,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  borderColor: selectedItemIndex === index ? 'primary.light' : 'grey.200',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: selectedItemIndex === index ? 'primary.main' : 'grey.300',
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography color="text.primary" variant="body2" fontWeight="bold">
                      {title}
                    </Typography>
                    <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            style={{ backgroundColor: 'white' }}
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: () => `url(${carouselImg[actualImg]})`,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
