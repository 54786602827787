import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { alpha, Avatar, Box, Container, Paper, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { usePartnerDetails, usePartnerReports } from 'src/api/bikApi';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import PartnerLinkSection from 'src/pages/partner-dashboard/PartnerLinkSection';
import { PartnerReport } from 'src/types/PartnerReport';
import LoadingSite from './commonSites/LoadingSite';

const ROWS_PER_PAGE = 5;

// DataTable Component - Displays the data in a tabular format
interface DataTableProps {
  rows: PartnerReport[];
  page: number;
  rowsPerPage: number;
  totalElements: number;
  isLoading: boolean;
  handlePageChange: (newPage: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  rows,
  page,
  rowsPerPage,
  totalElements,
  isLoading,
  handlePageChange,
}) => {
  const theme = useTheme();

  // Columns definition for DataGrid
  const columns: GridColDef[] = [
    { field: 'reportType', headerName: 'Rodzaj raportu', flex: 1, sortable: true },
    { field: 'company', headerName: 'Firma', flex: 1 },
    { field: 'clientName', headerName: 'Nazwa klienta', flex: 1 },
    { field: 'price', headerName: 'Wartość usługi', flex: 1 },
    { field: 'discountPrice', headerName: 'Cena po rabacie', flex: 1 },
    { field: 'createdAt', headerName: 'Data usługi', flex: 1 },
  ];

  // Transform rows for display
  const transformedRows = rows.map((row, index) => ({
    id: index,
    reportType: row.reportType,
    clientName: row.customer?.fullName || 'Brak',
    price: `${row.price} zł`,
    discountPrice: `${row.discountPrice} zł`,
    createdAt: new Date(row.createdAt).toLocaleString('pl-PL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    company: row?.company?.name || 'Brak',
  }));

  // Handle pagination model change
  const handlePaginationModelChange = (model: { page: number; pageSize: number }) => {
    if (!isLoading) {
      handlePageChange(model.page);
    }
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
      <DataGrid
        rows={transformedRows}
        columns={columns}
        paginationMode="server"
        paginationModel={{ page, pageSize: rowsPerPage }}
        onPaginationModelChange={handlePaginationModelChange}
        rowCount={totalElements}
        loading={isLoading}
        slots={{
          toolbar: GridToolbar,
        }}
        pageSizeOptions={[ROWS_PER_PAGE]}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSorting
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.light,
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.2),
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  );
};

// Main Component - PartnerDashboard
const PartnerDashboard: React.FC = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);

  // Fetching Partner details and reports
  const { data: partnerDetails, isLoading: isPartnerDetailsLoading } = usePartnerDetails();
  const { data: reports, isLoading: isPartnerReportsLoading } = usePartnerReports(page + 1, ROWS_PER_PAGE);

  const handlePageChange = (newPage: number) => setPage(newPage);
  const rows: PartnerReport[] = reports?.items || [];
  const totalElements = reports?.totalElements || 0;

  // Loading state
  if (isPartnerDetailsLoading) {
    return <LoadingSite />;
  }

  const partnerLink = `${location.origin}/process-bik?ref=${partnerDetails?.friendlyId}`;

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />

      {/* Container for the Dashboard */}
      <Container
        id="dashboard"
        sx={{
          pt: { xs: 8, sm: 10 },
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={4}
          sx={{
            width: '100%',
            py: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 5 }, // Adjusted horizontal padding for mobile
            mt: 2,
            mb: 4,
            bgcolor: theme.palette.background.paper,
            borderRadius: 3,
            boxShadow: `0 4px 8px ${alpha(theme.palette.primary.dark, 0.2)}`,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Column layout for mobile, row for desktop
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: { xs: 2, sm: 0 }, // Adds spacing between elements for mobile
          }}
        >
          {/* Partner Info Box */}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}>
            {/* Partner Avatar */}
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.dark,
                width: { xs: 48, sm: 64 }, // Smaller avatar on mobile
                height: { xs: 48, sm: 64 },
                mr: { xs: 2, sm: 3 }, // Adjusted right margin for mobile
              }}
            >
              <AccountCircleIcon />
            </Avatar>

            {/* Partner Details */}
            <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                {`Partner: ${partnerDetails?.firstName} ${partnerDetails?.lastName}`}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                {partnerDetails?.email}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                {`Telefon: ${partnerDetails?.phone || 'N/A'}`}
              </Typography>
            </Box>
          </Box>

          <PartnerLinkSection partnerLink={partnerLink} />
        </Paper>

        {/* Data Table */}
        <DataTable
          rows={rows}
          page={page}
          rowsPerPage={ROWS_PER_PAGE}
          totalElements={totalElements}
          isLoading={isPartnerReportsLoading}
          handlePageChange={handlePageChange}
        />
      </Container>
      <Footer />
    </>
  );
};

export default PartnerDashboard;
