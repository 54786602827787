import { styled } from '@mui/material';
import Grid from '@mui/material/Grid2';

export const getDebtColor = (longestDebt?: number, isFullVisibility?: boolean) => {
  let color = '#79c44b29';

  if (!longestDebt && longestDebt !== 0) {
    return isFullVisibility ? color.substring(0, color.length - 2) : color;
  }

  if (longestDebt > 0 && longestDebt <= 30) {
    color = '#fbb14b29';
    return isFullVisibility ? color.substring(0, color.length - 2) : color;
  }

  if (longestDebt > 30 && longestDebt <= 60) {
    color = '#e95c5629';
    return isFullVisibility ? color.substring(0, color.length - 2) : color;
  }

  if (longestDebt > 60) {
    color = '#80808078';
    return isFullVisibility ? color.substring(0, color.length - 2) : color;
  }

  return isFullVisibility ? color.substring(0, color.length - 2) : color;
};

export const getDebtText = (longestDebt?: number) => {
  if (!longestDebt && longestDebt !== 0) {
    return 'Dobra';
  }

  if (longestDebt > 0 && longestDebt <= 30) {
    return 'Słaba';
  }

  if (longestDebt > 30 && longestDebt <= 60) {
    return 'Zła';
  }

  if (longestDebt > 60) {
    return 'Bardzo zła';
  }

  return 'Dobra';
};

const forbiddenProps = ['$longestDebt', '$isRemovable'];

export const BIKSection = styled(Grid, { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $longestDebt?: number;
  $isRemovable: boolean;
}>(({ $isRemovable }) => ({
  borderRadius: '8px',
  border: '2px solid #00000014',
  padding: '16px',
  transition: $isRemovable ? 'transform 0.3s ease-in-out' : '',
  cursor: $isRemovable ? 'pointer' : '',
  '&:hover': $isRemovable
    ? {
        transform: 'scale(1.02)',
      }
    : {},
}));

export const GridTitle = styled(Grid)(() => ({
  fontWeight: 600,
}));

export const BIKItem = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#ffffffe6',
  textAlign: 'center',
  borderRadius: '8px',
  border: '1px solid #e8e8e88a',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));
