import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GppGoodIcon from '@mui/icons-material/GppGood';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SupportIcon from '@mui/icons-material/Support';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const lovedByContent = [
  {
    text: 'Zawsze pod ręką',
    icon: <FavoriteIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Bezpieczeństwo i prywatność',
    icon: <GppGoodIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Dostępne finansowo',
    icon: <CreditCardIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Funkcje wspierane przez AI',
    icon: <PsychologyIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Pełna pomoc techniczna',
    icon: <SupportIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Bezdyskusyjna transparentność',
    icon: <VisibilityIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Sprawdzone rozwiązania',
    icon: <ToggleOnIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
  {
    text: 'Dziecinnie proste w użytkowaniu',
    icon: <ChildFriendlyIcon style={{ fontSize: '3em', color: '#01347b' }} />,
  },
];

export default function TrustedBy() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4 },
      }}
    >
      <Grid container textAlign="center" justifyContent="center" spacing={4}>
        <Grid size={{ xs: 12 }}>
          <p style={{ fontSize: '2em', fontWeight: '600' }}>
            Za co nasi użytkwnicy <b>kochają</b>{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '1em',
                fontWeight: 600,
                color: 'primary.main',
              }}
            >
              BIKfirmowy
            </Typography>
            .pl
          </p>
        </Grid>
        {lovedByContent.map((item, index) => (
          <Grid key={index} size={{ xs: 6, md: 3 }}>
            {item.icon} <br />
            <b>{item.text}</b>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
