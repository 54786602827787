import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';

interface CommunicationPreferencesModalProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: CommunicationPreferences) => Promise<boolean>;
}

const CommunicationPreferencesModal: React.FC<CommunicationPreferencesModalProps> = ({
  open,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CommunicationPreferences>({
    defaultValues: {
      emailEnabled: false,
      smsEnabled: false,
      email: '',
      phone: '',
      consent1: false,
      consent2: false,
    },
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isSent, setIsSent] = useState(false);

  // Watch the checkbox states for enabling/disabling inputs
  const emailEnabled = watch('emailEnabled');
  const smsEnabled = watch('smsEnabled');

  // Watch the input values for email and phone
  const emailValue = watch('email');
  const phoneValue = watch('phone');

  useEffect(() => {
    if (!isChecked) {
      return;
    }

    // Custom validation: if neither email nor SMS is enabled, set an error on emailEnabled and smsEnabled
    if (!emailEnabled && !smsEnabled) {
      setError('emailEnabled', { type: 'manual', message: 'Wybierz przynajmniej jedną formę komunikacji.' });
      setError('smsEnabled', { type: 'manual', message: 'Wybierz przynajmniej jedną formę komunikacji.' });
    } else {
      clearErrors('emailEnabled');
      clearErrors('smsEnabled');
    }

    // Ensure one of the fields is required based on the enabled checkboxes
    if (emailEnabled && !emailValue) {
      setError('email', { type: 'required', message: 'Adres email jest wymagany.' });
    } else {
      clearErrors('email');
    }

    if (smsEnabled && !phoneValue) {
      setError('phone', { type: 'required', message: 'Numer telefonu jest wymagany.' });
    } else {
      clearErrors('phone');
    }
  }, [emailEnabled, smsEnabled, emailValue, phoneValue, setError, clearErrors]);

  const handleFormSubmit = (data: CommunicationPreferences) => {
    setIsChecked(true);
    setIsSent(false);
    // Additional email and phone validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phonePattern = /^(\+48)?\s?\d{9}$/; // Validates Polish phone numbers with optional +48 prefix

    if (!emailEnabled && !smsEnabled) {
      setError('emailEnabled', { type: 'manual', message: 'Wybierz przynajmniej jedną formę komunikacji.' });
      return;
    }

    if (emailEnabled && !emailPattern.test(data?.email || '')) {
      setError('email', { type: 'manual', message: 'Podaj poprawny adres email.' });
      return;
    }

    if (smsEnabled && !phonePattern.test(data?.phone || '')) {
      setError('phone', { type: 'manual', message: 'Podaj poprawny numer telefonu (9 cyfr).' });
      return;
    }

    // If validation passes, call onSubmit with valid data
    onSubmit(data).then((isSent) => setIsSent(isSent));
  };

  const handleCloseSnackbar = () => setIsSent(false);

  return (
    <>
      <Dialog open={open} onClose={() => !isLoading && onClose()} maxWidth="sm" fullWidth scroll="paper">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {/* Dialog Title */}
          <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', paddingY: 2 }}>
            <Typography variant="h6" component="div" textAlign="center">
              Preferencje komunikacyjne
            </Typography>
          </DialogTitle>

          {/* Dialog Content */}
          <DialogContent dividers sx={{ maxHeight: '600px', overflowY: 'auto', paddingX: 3 }}>
            {/* Intro Section */}
            <Box sx={{ paddingY: 2, backgroundColor: '#f5f5f5', borderRadius: 1, boxShadow: 1, mb: 3, p: 2 }}>
              <Typography variant="body1" component="p">
                Szanowni Państwo,
              </Typography>
              <Typography variant="body2" component="p" sx={{ marginTop: 1, lineHeight: 1.6 }}>
                Dokładamy wszelkich starań, aby nasz proces był szybki i niezawodny. Jednak ze względu na dynamiczne
                warunki, czasami mogą wystąpić nieoczekiwane trudności. W trakcie przetwarzania Państwa pliku pojawił
                się błąd, który uniemożliwił kontynuację procesu. Zdajemy sobie sprawę, jak ważny jest dla Państwa czas,
                dlatego natychmiast przystępujemy do działania. Rozpoczęliśmy prace nad wygenerowaniem nowego raportu.
                Gdy będzie gotowy, niezwłocznie prześlemy informację na wybraną przez Państwa formę kontaktu. W
                wiadomości znajdą Państwo bezpośredni link do raportu, który umożliwi szybkie zapoznanie się z opcjami
                dotyczącymi wyczyszczenia Państwa BIK.
              </Typography>
            </Box>

            {/* Communication Preference Section */}
            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
              Preferowana forma kontaktu
            </Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
              <FormLabel>Wybierz jedną lub więcej form komunikacji</FormLabel>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1 }}>
                {/* Email Checkbox */}
                <FormControlLabel
                  control={
                    <Controller
                      name="emailEnabled"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{ '&.Mui-checked': { color: 'primary.main' } }}
                        />
                      )}
                    />
                  }
                  label="Email"
                />

                {/* SMS Checkbox */}
                <FormControlLabel
                  control={
                    <Controller
                      name="smsEnabled"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{ '&.Mui-checked': { color: 'primary.main' } }}
                        />
                      )}
                    />
                  }
                  label="SMS"
                />
              </FormGroup>
              {(errors.emailEnabled || errors.smsEnabled) && (
                <FormHelperText error>{errors.emailEnabled?.message || errors.smsEnabled?.message}</FormHelperText>
              )}
            </FormControl>

            {/* Email Input */}
            <FormControl fullWidth margin="normal" error={!!errors.email}>
              <FormLabel>Adres email</FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Wpisz adres email"
                    disabled={!emailEnabled} // Disable if the checkbox is not checked
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={{
                      '& .Mui-disabled': { bgcolor: 'action.disabledBackground' },
                    }}
                  />
                )}
              />
            </FormControl>

            {/* Phone Input */}
            <FormControl fullWidth margin="normal" error={!!errors.phone}>
              <FormLabel>Numer telefonu</FormLabel>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Wpisz numer telefonu"
                    disabled={!smsEnabled} // Disable if the checkbox is not checked
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    sx={{
                      '& .Mui-disabled': { bgcolor: 'action.disabledBackground' },
                    }}
                  />
                )}
              />
            </FormControl>

            {/* Consent Section */}
            <FormControl component="fieldset" margin="normal" sx={{ mt: 1 }}>
              <FormLabel>Zgody marketingowe</FormLabel>
              <FormGroup>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Controller
                      name="consent1"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{ '&.Mui-checked': { color: 'primary.main' } }}
                        />
                      )}
                    />
                  }
                  label="Wyrażam zgodę na kontakt przez firma Kamil Derendarz, ul. Dobrego Pasterza 118D/51, 31-416 Kraków; NIP: 7712703730, za pośrednictwem telefonu (połączenia telefoniczne, SMS, MMS), w celu przekazywania informacji handlowych, na podany przeze mnie numer telefonu."
                />
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Controller
                      name="consent2"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{ '&.Mui-checked': { color: 'primary.main' } }}
                        />
                      )}
                    />
                  }
                  label="Wyrażam zgodę na przesyłanie przez firma Kamil Derendarz, ul. Dobrego Pasterza 118D/51, 31-416 Kraków; NIP: 7712703730; informacji handlowych, za pomocą środków komunikacji elektronicznej, na podany przeze mnie adres e-mail."
                />
              </FormGroup>
            </FormControl>
          </DialogContent>

          {/* Dialog Actions */}
          <DialogActions sx={{ padding: 2, justifyContent: 'space-between', borderTop: '1px solid #e0e0e0' }}>
            <Button onClick={onClose} color="secondary" sx={{ textTransform: 'none' }}>
              Anuluj
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!watch('consent1') || !watch('consent2') || isLoading}
              sx={{ textTransform: 'none', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Wyślij'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={isSent}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Zgłoszenie zostało pomyślnie wysłane."
      />
    </>
  );
};

export default CommunicationPreferencesModal;
