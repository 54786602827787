import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ScrollToTop from 'src/components/ScrollToTop';
import SecurityInfo from 'src/pages/main/SecurityInfo';
import SuccessSteps from 'src/pages/main/SuccessSteps';
import TrustedBy from 'src/pages/main/TrustedBy';
import AppToolbar from '../components/app-toolbar/AppToolbar';
import Footer from '../components/Footer';
import ImproveBIK from '../pages/main/ImproveBIK';
import ProcessSteps from '../pages/main/ProcessSteps';
import Services from '../pages/main/Services';

// Define the props for Section component
interface SectionProps {
  children: React.ReactNode;
  hasDivider?: boolean; // Optional prop to determine if divider is shown
}

// Section component with optional divider
const Section: React.FC<SectionProps> = ({ children, hasDivider = true }) => (
  <>
    {children}
    {hasDivider && <Divider />}
  </>
);

export default function LandingPage() {
  return (
    <>
      <ScrollToTop />
      <AppToolbar type="main" />
      <ImproveBIK />
      <MainContent />
    </>
  );
}

const MainContent: React.FC = () => (
  <Box sx={{ bgcolor: 'background.default' }}>
    <Section>
      <ProcessSteps />
    </Section>
    <Section>
      <Services />
    </Section>
    <Section>
      <SuccessSteps />
    </Section>
    <Section>
      <SecurityInfo />
    </Section>
    <Section hasDivider={false}>
      <TrustedBy />
    </Section>
    <Footer />
  </Box>
);
