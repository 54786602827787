import { Grid2, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { CustomCheckbox } from 'src/components/custom-checkbox/CustomCheckbox';

export const ImproveImg = styled('div')(() => ({
  backgroundImage: 'url(improve.jpg)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  minHeight: '300px',
}));

export const Main = styled(Box)(({ theme }) => ({
  ...theme.unstable_sx({
    width: '100%',
    backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
    backgroundSize: '100% 20%',
    backgroundRepeat: 'no-repeat',
  }),
}));

export default function ImproveBIK() {
  const navigate = useNavigate();

  return (
    <Main id="about-us">
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Grid2 spacing={2} container>
          <Grid2 size={{ xs: 12, sm: 7 }}>
            <Stack spacing={2} useFlexGap>
              <Typography
                variant="h1"
                sx={{
                  fontSize: 'clamp(3.0rem, 10vw, 2rem)',
                }}
              >
                Sprawdź swój BIK w 30 sekund i zobacz, co możesz usunąć!
              </Typography>
              <Typography color="text.secondary" sx={{ width: { sm: '100%', md: '80%' } }}>
                Nasze narzędzie analizuje cały raport, pokazuje, co usunąć i automatycznie tworzy potrzebne dokumenty.
                Skup się na biznesie, a my zadbamy o Twój BIK!
              </Typography>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <CustomCheckbox defaultChecked disabled /> Obniż marżę kredytową
                </li>
                <li>
                  <CustomCheckbox defaultChecked disabled /> Zmniejsz prowizje
                </li>
                <li>
                  <CustomCheckbox defaultChecked disabled /> Zredukuj oprocentowanie
                </li>
                <li>
                  <CustomCheckbox defaultChecked disabled /> Zmniejsz wymaganą wpłatę własną na leasing
                </li>
              </ul>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
              >
                <Button variant="contained" size="large" onClick={() => navigate('/process-bik')}>
                  Sprawdź swój BIK
                </Button>
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 5 }}>
            <ImproveImg />
          </Grid2>
        </Grid2>
      </Container>
    </Main>
  );
}
