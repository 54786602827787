import { BIKReportType } from 'src/types/BIKReport.type';

export const calculateFinalPrice = (
  selectedPromoCode: { code: string; value: number },
  total: Record<BIKReportType, number>
): number => {
  if (selectedPromoCode.value > 0 && selectedPromoCode.value <= 1) {
    return Number(((total.COMPANY + total.CUSTOMER) * (1 - selectedPromoCode.value)).toFixed(2));
  }

  if (selectedPromoCode.value > 1 && total.COMPANY + total.CUSTOMER >= selectedPromoCode.value) {
    return selectedPromoCode.value;
  }

  return total.COMPANY + total.CUSTOMER;
};
