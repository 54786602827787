import { z } from "zod";

export const bikUploadSchema = z.object({
    type: z.string(),
    attachments: z.object({
      companyPdf: z.custom<File>().optional(),
      companyAgreementOne: z.boolean(),
      customerPdf: z.custom<File>().optional(),
      customerAgreementOne: z.boolean(),

    }).partial()
    .refine(
      data => !!data.companyPdf || !!data.customerPdf,
      'Wymagane jest wgranie BIKu prywatnego lub firmowego aby przejść dalej.',
    )
    .refine(
      data => {
        if (!!data.companyPdf && !data.companyAgreementOne) {
            return false;
        }
        if (!!data.customerPdf && !data.customerAgreementOne) {
            return false;
        }

        return true;
      },
      'Proszę zaakceptować wszystkie zgody dotyczące przesłanego pliku PDF.',
    )
  });
