import { FetchError } from './bikApi';

export const handleApiError = (error: unknown): string => {
  if (!(error instanceof FetchError)) {
    return 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.';
  }

  const errorMessages: Record<number, string> = {
    400: 'Błędne dane. Proszę sprawdzić wprowadzone informacje.',
    401: 'Podano nie poprawne dane.',
    403: 'Brak uprawnień do tej operacji.',
    404: 'Nie znaleziono zasobu. Sprawdź poprawność adresu.',
    413: 'Wgrywany plik posiada zbyt duży rozmiar.',
    500: 'Błąd serwera. Spróbuj ponownie później.',
  };

  return errorMessages[error.status] || `Wystąpił błąd. Status: ${error.status}.`;
};
