import { Box, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPasswordWithToken } from 'src/api/bikApi'; // Make sure to implement this function in your API layer
import { handleApiError } from 'src/api/handleApiError';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';

export default function ResetPasswordPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // Get the token from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    // Validate input
    if (newPassword.length < 6) {
      setErrorMessage('Hasło musi mieć co najmniej 6 znaków.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Podane hasła muszą być identyczne.');
      return;
    }

    if (!token) {
      setErrorMessage('Token resetowania hasła jest nieprawidłowy.');
      return;
    }

    setLoading(true);

    try {
      // Call the API to reset the password
      const response = await resetPasswordWithToken(token, newPassword);
      if (response.status === 204) {
        setSuccessMessage('Hasło zostało pomyślnie zmienione. Przekierowywanie do strony logowania...');
        setTimeout(() => {
          navigate('/sign-in?message=Twoje hasło zostało zmienione. Możesz się teraz zalogować.');
        }, 4000);
      } else {
        setErrorMessage('Nie udało się zmienić hasła. Spróbuj ponownie później.');
      }
    } catch (error) {
      setErrorMessage(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ScrollToTop />
      <AppToolbar type="back" />
      <Container
        id="reset-password-page"
        sx={{
          pt: { xs: 4, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Paper elevation={3} sx={{ bgcolor: 'white', padding: 4, borderRadius: 2, width: '100%' }}>
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                  Ustaw nowe hasło
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="Nowe hasło"
                  type="password"
                  id="newPassword"
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Potwierdź nowe hasło"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errorMessage && (
                  <Typography color="error" align="center" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
                {successMessage && (
                  <Typography color="primary" align="center" sx={{ mt: 2 }}>
                    {successMessage}
                  </Typography>
                )}
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? 'Przetwarzanie...' : 'Ustaw nowe hasło'}
                </Button>
              </Paper>
            </Box>
          </Container>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
