import { Box, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BIKReportType } from 'src/types/BIKReport.type';

const SelectAllComponent = ({
  isSelectedAll,
  setIsSelectedAll,
}: {
  isSelectedAll: boolean;
  setIsSelectedAll: (isSelectedAll: boolean) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        backgroundColor: isSelectedAll ? '#0fa789' : '#e95c56',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        flexWrap: 'wrap',
        height: '100%',
        width: { sx: '100%', sm: '80%' },
        p: 1,
        minHeight: '50px',
        cursor: 'pointer',
      }}
      onClick={() => setIsSelectedAll(!isSelectedAll)}
    >
      <Typography
        variant="body1"
        sx={{ fontWeight: 600, marginRight: '10px', fontSize: { sx: 12, sm: 16 }, textAlign: 'center' }}
      >
        {isSelectedAll ? 'Odznacz' : 'Zaznacz'} wszystkie pozycje rekomendowane do usunięcia
      </Typography>

      <Switch
        checked={isSelectedAll}
        color="primary"
        sx={{
          '.MuiSwitch-thumb': {
            color: isSelectedAll ? '#4caf50' : '#f44336',
          },
        }}
      />
    </Box>
  );
};

export const SummaryTopTitle = ({
  isSelectedAll,
  type,
  showSelectAll,
  setIsSelectedAll,
}: {
  isSelectedAll: boolean;
  type: BIKReportType;
  showSelectAll: boolean;
  setIsSelectedAll: (isSelectedAll: boolean) => void;
}) => {
  return (
    <Grid container spacing={2} width="100%">
      <Grid
        size={{ xs: 12 }}
        color={'#000b9dbd'}
        borderRadius={'12px'}
        textAlign={'center'}
        fontSize={'32px'}
        fontWeight={'600'}
      >
        Wynik raportu BIK {type === 'CUSTOMER' ? 'Prywatnego' : 'Firmowego'}
      </Grid>

      {showSelectAll && (
        <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
          <SelectAllComponent isSelectedAll={isSelectedAll} setIsSelectedAll={setIsSelectedAll} />
        </Grid>
      )}
    </Grid>
  );
};
