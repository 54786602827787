import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box, Grid2, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

export const SecurityImg = styled('div')(() => ({
  backgroundImage: 'url(security.png)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  minHeight: '300px',
}));

export const Title = styled(Box)(() => ({
  fontSize: '3em',
  fontWeight: '600',
  lineHeight: '40px',
}));

export default function SecurityInfo() {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 4 },
      }}
    >
      <Grid2 spacing={2} container>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Stack spacing={2} useFlexGap>
            <Title>
              <b>Bezpieczeństwo danych</b> na najwyższym poziomie!
            </Title>
            <Box>
              <Stack alignItems="center" direction="row" maxWidth="500px" gap={2}>
                <LooksOneIcon style={{ fontSize: '4em', color: 'darkgray' }} />
                <Typography variant="body1">
                  Wszystkie <b>dane są szyfrowane</b> i przetwarzane wyłącznie podczas automatycznej ewaluacji BIK,
                  realizowanej przez nasz autorski algorytm.
                </Typography>
              </Stack>
              <Stack alignItems="center" direction="row" maxWidth="500px" gap={2}>
                <LooksTwoIcon style={{ fontSize: '4em', color: 'darkgray' }} />
                <Typography variant="body1">
                  Jesteśmy w pełni <b>zgodni z RODO</b> i spełniamy najwyższe europejskie standardy ochrony prywatności
                  i bezpieczeństwa danych.
                </Typography>
              </Stack>
              <Stack alignItems="center" direction="row" maxWidth="500px" gap={2}>
                <Looks3Icon style={{ fontSize: '4em', color: 'darkgray' }} />
                <Typography variant="body1">
                  Nigdy <b>nie udostępniamy danych naszych użytkowników</b>. Nasz zysk pochodzi ze wspierania Cię w
                  procesie obsługi dokumentacji do korekty BIK, a nie z wykorzystywania Twoich danych.
                </Typography>
              </Stack>
            </Box>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
            >
              <Button variant="contained" size="large" onClick={() => navigate('/process-bik')}>
                Sprawdź swój BIK
              </Button>
            </Stack>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <SecurityImg />
        </Grid2>
      </Grid2>
    </Container>
  );
}
