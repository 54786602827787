export const handleElementGrammar = (count: number) => {
    if (count === 1) {
      return 'element';
    }
  
    if (count >= 2 && count <= 4) {
      return 'elementy';
    }
  
    return 'elementów';
  };