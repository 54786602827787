import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const TitleContainer = styled(Box)(() => ({
  color: '#01347b',
  textAlign: 'center',
  svg: {
    width: '2em',
    height: '2em',
  },
}));

const items = [
  {
    icon: <AccountCircleIcon />,
    name: 'S. Kowalik',
    description:
      'BIK Firmowy to firma, którą zdecydowanie warto polecić. Skutecznie usunęli leasingi samochodowe z mojego BIK, wykazując się profesjonalizmem, rzetelnością i efektywnością. Jeśli potrzebujesz pomocy w rozwiązaniu takiego problemu, to doskonały wybór. Z pełnym przekonaniem polecam!',
  },
  {
    icon: <AccountCircleIcon />,
    name: 'K. Ostępowicz',
    description:
      'Jako właściciel firmy transportowej, utrzymanie czystego konta w BIK było dla mnie kluczowe, aby skutecznie prowadzić biznes. Dzięki BIK Firmowy udało mi się usunąć leasingi z mojego raportu BIK, co pozwala mi teraz w pełni skupić się na rozwoju firmy. Jeśli potrzebujesz wsparcia w tym obszarze, gorąco polecam BIK Firmowy.',
  },
  {
    icon: <AccountCircleIcon />,
    name: 'M. Kołodziej',
    description:
      'Ogromne podziękowania dla BIK Firmowy za pomoc w usunięciu leasingów z mojego BIK. Zależało mi na utrzymaniu wiarygodności mojej firmy transportowej i na tym, aby nie wpływało to negatywnie na moją zdolność kredytową. BIK Firmowy wykonała świetną pracę, dzięki czemu teraz czuję się pewnie z czystym BIK. Zdecydowanie polecam ich usługi!',
  },
];

export default function SuccessSteps() {
  return (
    <Box
      id="steps"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#01347b',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Skuteczność potwierdzona przez klientów
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 12, md: 4 }} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  color: 'black',
                  border: '1px solid',
                  borderColor: '#04123d',
                  backgroundColor: 'white',
                }}
              >
                <TitleContainer>{item.icon}</TitleContainer>
                <Typography variant="body1">{item.description}</Typography>
                <Typography variant="body2">{item.name}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
