import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const TitleContainer = styled(Box)(() => ({
  color: '#01347b',
  display: 'flex',
  gap: '4px',
  svg: {
    width: '2em',
    height: '2em',
  },
}));

const items = [
  {
    icon: <VerifiedUserOutlinedIcon />,
    title: 'Przesyłasz bezpiecznie dokumenty BIK',
    description:
      'Za pośrednictwem naszego zaufanego serwisu bezpiecznie przesyłasz zaszyfrowane dokumenty BIK firmowy oraz właściciela firmy do analizy.',
  },
  {
    icon: <PendingActionsOutlinedIcon />,
    title: 'Przeprowadzamy bezpłatna analizę BIK z AI',
    description:
      'Dzięki zaawansowanej technologii AI bezpłatnie analizujemy Twój raport z BIK i w 30 sekund otrzymujesz rekomendacje, prognozę poprawy scoringu oraz możliwość zakupu usługi poprawy BIK.',
  },
  {
    icon: <PictureAsPdfOutlinedIcon />,
    title: 'Otrzymujesz zestaw spersonalizowanych dokumentów',
    description:
      'Po zakupie usługi otrzymasz od nas komplet dokumentów, które pozwolą Ci podjąć niezbędne działania. Wysyłamy je do Ciebie, ponieważ z doświadczenia wiemy, że samodzielne wysyłanie dokumentów zwiększa skuteczność i przyspiesza ich procesowanie.',
  },
  {
    icon: <FactCheckOutlinedIcon />,
    title: 'Korzystaj ze zdolności kredytowej i lepszych ofert finansowania.',
    description:
      'Twoja historia w BIK została poprawiona, dzięki czemu Twój scoring umożliwia staranie się o finansowanie w szerokim zakresie instytucji finansowych, bez obaw o odmowę.',
  },
];

export default function ProcessSteps() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#01347b',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Jak wygląda nasz proces?
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 3 }} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  color: 'black',
                  border: '1px solid',
                  borderColor: '#04123d',
                  backgroundColor: 'white',
                }}
              >
                <TitleContainer>
                  {item.icon}
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                </TitleContainer>
                <div>
                  <Typography variant="body2">{item.description}</Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
