import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { PdfDragNDrop } from 'src/components/PdfDragNDrop';
import { BIKUploadValidationSchema } from 'src/types/FormField.type';

export default function CustomerPart({
  type,
  control,
}: {
  type: 'OWNER' | 'SUPPORT';
  control: Control<BIKUploadValidationSchema>;
}) {
  const [accepted, setAccepted] = useState(false);

  const handleAcceptClick = (event: ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  return (
    <Box mt={2}>
      <Typography variant="h5" gutterBottom>
        2. Dodaj BIK osobisty właściciela firmy (opcjonalny)
      </Typography>

      <Controller
        name="attachments.customerPdf"
        control={control}
        render={({ field }) => <PdfDragNDrop {...field} />}
      />

      <Box
        sx={{
          backgroundColor: accepted ? '#8393d02b' : '#f9f9f9',
          padding: '20px',
          minHeight: '280px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginTop: '20px',
          marginBottom: '20px',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Zgoda na przetwarzanie danych osobowych {accepted ? ' (Zaakceptowano)' : ''}
        </Typography>

        {type === 'OWNER' && (
          <>
            <Typography variant="body1" gutterBottom>
              Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i
              Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. (ogólne rozporządzenie o ochronie danych) oraz ustawą z dnia
              10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018r., poz. 1000 z późn. zm.) przez BIKfirmowy.pl w
              celu analizy stanu historii kredytowej oraz sugestii co można poprawić w celu poprawy oceny BIK i poprawy
              zdolności kredytowej.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Podaję dane osobowe dobrowolnie i oświadczam, że są one zgodne z prawdą.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Zapoznałem/łam się z treścią klauzuli informacyjnej, w tym z informacją dotyczącą celu oraz metod
              przetwarzania danych osobowych, a także z prawem dostępu do swoich danych oraz ich poprawiania.
            </Typography>
          </>
        )}

        {type === 'SUPPORT' && (
          <>
            <Typography variant="body1" gutterBottom>
              Wyrażam zgodę na przetwarzanie danych osobowych mojego klienta, działając w jego imieniu oraz na podstawie
              uzyskanych od niego niezbędnych zgód i umów, zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady
              (UE) 2016/679 z dnia 27 kwietnia 2016 r. (ogólne rozporządzenie o ochronie danych - RODO) oraz ustawą z
              dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018 r., poz. 1000, z późn. zm.), przez
              BIKfirmowy.pl, w celu analizy historii kredytowej oraz przedstawienia sugestii dotyczących możliwych
              działań mających na celu poprawę oceny BIK i zdolności kredytowej.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Dane osobowe mojego klient przekazuję dobrowolnie i w jego imieniu oświadczam, że są one zgodne z prawdą.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Zapoznałem/łam się z treścią klauzuli informacyjnej, w tym z informacją dotyczącą celu oraz metod
              przetwarzania danych osobowych, a także z prawem dostępu do swoich danych oraz ich poprawiania.
            </Typography>
          </>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Controller
          name="attachments.customerAgreementOne"
          defaultValue={false}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event);
                    handleAcceptClick(event);
                  }}
                  color="primary"
                />
              }
              label={<Typography style={{ marginLeft: 4 }}>Wyrażam zgodę</Typography>}
            />
          )}
        />
      </Box>
    </Box>
  );
}
