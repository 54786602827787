import { z } from 'zod';

const privateDataSchema = z.object({
  fullName: z.string().min(1, 'Imię i Nazwisko jest wymagane'),
  phone: z.string().min(1, 'Telefon jest wymagany'),
  email: z.string().email('Nieprawidłowy format e-maila'),
  country: z.string().min(1, 'Kraj jest wymagany'),
  city: z.string().min(1, 'Miasto jest wymagane'),
  postalCode: z.string().regex(/^\d{2}-\d{3}$/, 'Kod pocztowy powinien być w formacie 00-000'),
  street: z.string().optional(),
  buildingNumber: z.string().min(1, 'Numer budynku/biura jest wymagany'),
  pesel: z.string().length(11, 'PESEL powinien mieć 11 cyfr'),
}).optional();

const companyDataSchema = z.object({
  companyName: z.string().min(1, 'Nazwa firmy jest wymagana'),
  invoiceEmail: z.string().email('Nieprawidłowy format e-maila'),
  country: z.string().min(1, 'Kraj jest wymagany'),
  city: z.string().min(1, 'Miasto jest wymagane'),
  postalCode: z.string().regex(/^\d{2}-\d{3}$/, 'Kod pocztowy powinien być w formacie 00-000'),
  street: z.string().optional(),
  buildingNumber: z.string().min(1, 'Numer budynku/biura jest wymagany'),
  nip: z.string().length(10, 'NIP powinien mieć 10 cyfr'),
  regon: z.string().regex(/^\d{9}(\d{5})?$/, 'REGON powinien mieć 9 lub 14 cyfr'),
}).optional();

const supportDataSchema = z.object({
  fullName: z.string().min(1, 'Imię i Nazwisko jest wymagane'),
  phone: z.string().min(1, 'Telefon jest wymagany'),
  email: z.string().email('Nieprawidłowy format e-maila'),
});

const marketingConsentsSchema = z.object({
  phoneConsent: z.boolean().default(false).refine((val) => val, 'Musisz zaakceptować zgodę marketingową'),
  emailConsent: z.boolean().default(false).refine((val) => val, 'Musisz zaakceptować zgodę marketingową'),
});

export const detailsSchema = z.object({
  privateData: privateDataSchema,
  companyData: companyDataSchema,
  supportData: supportDataSchema,
  marketingConsents: marketingConsentsSchema,
});

export type AddDetailsSchema = z.infer<typeof detailsSchema>;
