import { useQuery } from "@tanstack/react-query";
import { isUserLogged } from "src/components/PrivateRoute";
import { BIKPrices } from "src/types/BIKPrices.type";
import { BIKReportUserData } from "src/types/BIKReportUserData";
import { CommunicationPreferences } from "src/types/CommunicationPreferences.type";
import { Company } from "src/types/Company";
import { CompanyBIKSummary } from "src/types/CompanyBIKSummary.type";
import { Customer } from "src/types/Customer";
import { PartnerDetails } from "src/types/PartnerDetails";
import { PartnerReport } from "src/types/PartnerReport";
import { SelectedRow } from "src/types/SelectedRow";

const API_LINK = import.meta.env.VITE_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class FetchError extends Error {
  status: number;
  statusText: string;
  responseBody: string | null;

  constructor(status: number, statusText: string, responseBody: string | null) {
    super(`Error ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.responseBody = responseBody;

    // Maintains proper stack trace for where our error was thrown (only available on V8 engines)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

const getDefaultHeaders = (): Record<string, string> | undefined => {
  const token = localStorage.getItem('authToken');

  if (!token && !API_KEY) {
    return undefined;
  }

  const headers: Record<string, string> = {};

  if (API_KEY) {
    headers['X-Api-Key'] = API_KEY;
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const handleError = async (response: Response) => {
  if (!response.ok) {
    const errorBody = await response.text(); // Get the response body as text

    // Throw the custom FetchError object
    throw new FetchError(response.status, response.statusText, errorBody);
  }

  return response;
};


// Upload Functions
export const uploadCompanyBIKReport = async (reportId: string, file: File) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/upload-bik-company`);

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(url, {
    headers: getDefaultHeaders(),
    method: 'PUT',
    body: formData,
  });

  await handleError(response); // Handle errors
  return response;
};

export const uploadCustomerBIKReport = async (reportId: string, file: File) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/upload-bik-customer`);

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(url, {
    headers: getDefaultHeaders(),
    method: 'PUT',
    body: formData,
  });

  await handleError(response); // Handle errors
  return response;
};

// BIK Report Functions
export const createBIKReport = async (customerType: 'SUPPORT' | 'OWNER', ref?: string) => {
  const url = new URL(`${API_LINK}/bik-reports`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customerType, ref }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

// Entry Selection Functions
export const chooseEntriesToRemove = async (reportId: string, entries: Omit<SelectedRow, 'id' | 'price'>[]) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/choose-entries-to-remove`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ entries }),
  });

  await handleError(response); // Handle errors
  return response;
};

// Entry Selection Functions
export const sendContactRequest = async (reportId: string, data: CommunicationPreferences) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/contacts`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      contactPersonEmailConsent: data.consent1,
      contactPersonEmail: data.email,
      contactPersonPhoneConsent: data.consent2,
      contactPersonPhone: data.phone
     }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const chooseRequestsEntriesToRemove = async (reportId: string, entries: Omit<SelectedRow, 'id' | 'price'>[]) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/choose-request-credit-entries-to-remove`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ entries }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const sendEntriesToRemove = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/send-entries-to-remove`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response); // Handle errors
  return response;
};

// Fetch a BIK Report
const getBIKReport = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useBIKReports = (reportId: string) => {
  return useQuery<CompanyBIKSummary>({
    queryKey: ['bik-report', reportId],
    queryFn: () => getBIKReport(reportId),
    retry: false,
    retryOnMount: false,
  });
};

const getPartnerReports = async (page = 0, perPage = 10) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePartnerReports = (page: number, perPage: number) => {
  return useQuery<{ totalElements: number; items: PartnerReport[] }>({
    queryKey: ['partner-reports', page, perPage],
    queryFn: () => getPartnerReports(page, perPage),
    retry: false,
    retryOnMount: false,
  });
};
const getPartnerDetails = async () => {
  if (!isUserLogged()) {
    return null;
  }

  const url = new URL(`${API_LINK}/partners/details`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePartnerDetails = () => {
  return useQuery<PartnerDetails>({
    queryKey: ['partner-details'],
    queryFn: () => getPartnerDetails(),
    retry: false,
    retryOnMount: false,
  });
};

export const createCustomerForBIKReport = async (customer: Customer) => {
  const url = new URL(`${API_LINK}/customers`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...customer }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const createCompanyForBIKReport = async (company: Company) => {
  const url = new URL(`${API_LINK}/companies`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...company }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const updateBIKReportUserData = async (reportId: string, data: BIKReportUserData) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/update-user-data`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateBIKPrices = async (reportId: string, prices: BIKPrices) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/update-prices`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...prices }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const signInPartner = async (email: string, password: string) => {
  const url = `${API_LINK}/partners/auth/login`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const resetPassword = async (email: string) => {
  const url = `${API_LINK}/partners/auth/recovery-password`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const resetPasswordWithToken = async (token: string, password: string) => {
  const url = `${API_LINK}/partners/auth/set-new-password`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, password }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const refreshToken = async () => {
  const url = `${API_LINK}/partners/auth/refresh-token`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};
