import CircleIcon from '@mui/icons-material/Circle';
import { FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BIKSection, getDebtColor, getDebtText } from './styles';
import { BIKItemRow } from './types';

interface BIKItemContentProps {
  item: BIKItemRow;
  index: number;
  isChecked: boolean;
  onCheckboxChange: (bikItem: BIKItemRow, index: number) => void;
}

export const BIKItemContent: React.FC<BIKItemContentProps> = ({ item, index, isChecked, onCheckboxChange }) => {
  const alignCenter = { alignContent: 'center', textAlign: 'center' };
  const isRemovable = item.enableToRemove;
  const debtText = getDebtText(item.longestDebt);
  const debtColor = getDebtColor(item.longestDebt, true);

  return (
    <BIKSection
      container
      size={{ xs: 12 }}
      key={index}
      spacing={0}
      sx={{ borderColor: isChecked ? '#3daf3d63' : '#00000014' }}
      $isRemovable={isRemovable}
      onClick={() => isRemovable && onCheckboxChange(item, index)}
    >
      <Grid size={{ xs: 3, md: 6 }}>
        {item.typeDescription} <br /> {item.subject}
      </Grid>
      <Grid size={{ xs: 1 }} sx={alignCenter}>
        <CircleIcon htmlColor={debtColor} />
      </Grid>
      <Grid size={{ xs: 1 }} sx={alignCenter}>
        {debtText}
      </Grid>
      <Grid size={{ xs: 3, md: 2 }} sx={alignCenter}>
        {isRemovable && debtText !== 'Dobra' ? 'Tak' : 'Nie'}
      </Grid>
      {isRemovable && (
        <>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            <FormControlLabel
              control={
                <Switch
                  disabled={!isRemovable}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(item, index)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${index}` }}
                />
              }
              label=""
            />
          </Grid>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            {item.price} zł
          </Grid>
        </>
      )}
    </BIKSection>
  );
};
