import { AppBar, Box, styled } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: '999px',
  backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
  backdropFilter: 'blur(24px)',
  maxHeight: 40,
  border: '1px solid',
  borderColor: 'divider',
  boxShadow: `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
}));

export const ToolbarContainer = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  ...theme.unstable_sx({
    boxShadow: 0,
    bgcolor: 'transparent',
    backgroundImage: 'none',
    mt: 2,
  }),
}));

export const Logo = styled('img')(() => ({
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
}));

export const DesktopBox = styled(Box)(({ theme }) => ({
  ...theme.unstable_sx({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    ml: '-18px',
    px: 0,
  }),
}));

export const MobileBox = styled(Box)(({ theme }) => ({
  ...theme.unstable_sx({
    minWidth: '60dvw',
    color: 'black',
    p: 2,
    backgroundColor: 'white',
    flexGrow: 1,
  }),
}));

export const MobileContent = styled(Box)(({ theme }) => ({
  ...theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    flexGrow: 1,
  }),
}));
