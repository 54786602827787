import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';

interface LoadingScreenProps {
  open: boolean;
  errorMessage?: string;
  loadingMessage: string;
  onClose: () => void;
}

export function LoadingScreen({ loadingMessage, open, errorMessage, onClose }: LoadingScreenProps) {
  return (
    <Modal
      open={open}
      onClose={errorMessage ? onClose : undefined} // Only allow closing when there's an error
      aria-labelledby="loading-modal"
      aria-describedby="loading-spinner"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 300,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
        }}
      >
        {errorMessage ? (
          <>
            <Typography variant="h6" textAlign="center" color="error">
              {errorMessage.includes('Dziękujemy') ? 'Zgłoszonie wysłane' : 'Error'}
            </Typography>
            <Typography variant="body1" textAlign="center" mt={2}>
              {errorMessage}
            </Typography>
            <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
              Zamknij
            </Button>
          </>
        ) : (
          <>
            <CircularProgress size={60} thickness={5} />
            <Typography variant="h6" mt={2} textAlign="center">
              {loadingMessage}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}
